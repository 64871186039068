// 3rd Party Dependencies
export const TWEEN = require('@tweenjs/tween.js'); // AY TODO: Weird .. the tween lib is exported and then used in TimeLine.vue and AnimationPreview.vue
const { pick, isEmpty } = require('lodash');
import { Color } from 'fabric';
import { currentAnimationConfigValue } from '../constants/animation';

// Internal dependencies
import {
  easingFunctions,
  resetProperties,
  animateConstants,
  typeNames,
  positions,
  download,
  minTime,
  stylesProperties,
  minimumDuration,
  loopTypes,
  TWEEN_START_VALUE,
  TWEEN_END_VALUE,
  MIN_TWEEN_DURATION,
  lastResetWait,
  foreverLoop,
  decreaseLetterLevelDuration,
  defaultFps,
  animationUpdateTypes,
  timings,
  direction,
  maxTime,
  animationLayerTypes,
  animationPreviewConstants
} from '../constants/animation';
import { levels } from '../constants/textStyles';
import { layers as layerTypes } from '../constants/layers';
import { deepAssign, getComputedLayer } from '../helpers/commonHelper';
import { tweenGenerator } from './tweenGenerator';
import { startAndEndTransitions, middleTransitions } from '../helpers/animationTransitions';
import {
  getAdHtmlCanvas,
  resetMedia,
  renderAnimations,
  generateMediaElements,
  getAudioContext,
  fetchAndWriteFile,
  splitFileToImages,
  deleteFile,
  backupVideoElements,
  prepareVideoFileUrls,
  getVideoData,
  replaceWithCDN
} from '../helpers/runtimeHelper';
import '../prototypes';
import { timelineDrag, timelineHelper } from '../helpers/timelineHelper';
import { formatTimeToMilliseconds } from './Utils';
import { updateSourceOnCanvas } from './multipleSourcesHelper';
import { store as $store } from 'Store/store'
import { calculateScaleForFitOption } from './objectSizingHelper';
/**
 * Complex object dict which has serious animation logic
 */
export const genericUtilFunctions = {

  /**
   * Generates the tweens for letter animations
   * @param {FabricText} fabricObject
   * @param {Number} basetime
   * @param {Number} duration
   * @param {Canvas} canvas
   * @param {*} animation
   * @param {*} currentPosition
   * @param {TWEEN.Group} timeline
   * @param {String} easing
   * @param {*} additionalGroups WTF is this?
   * @param {Number} layerStartTime
   * @param {Number} loopCount
   * @param {Number} loopDuration
   * @returns Array<tween>
   */
  letterUtilAnimationHandler (fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, additionalGroups, layerStartTime, loopCount, loopDuration, configs) {
    // Handling if the fabricObject is invalid
    if(!fabricObject || fabricObject._text.length == 0 || duration <= 0) return [];

    // init the required tweens to empty array
    var tweens = [];

    // init the backup object
    var tempBeforeAnimate = {};
    tempBeforeAnimate.opacity = 1;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    tempBeforeAnimate.padding = 10;

    // Gets the length of all characters in the text - excluding spaces and newlines
    var charactersLength = fabricObject._text.filter(e => e != ' ' && e != '\n').length;

    var lineIndex = 0;
    var currentCharIndex = -1;
    var i = 0;
    var l = charactersLength - 1;
    var durationPerCharacter = duration / charactersLength;

    var decreaseDuration = durationPerCharacter - decreaseLetterLevelDuration < 0 ? 0 : decreaseLetterLevelDuration;
    const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';
    do {
      const startIndex = currentCharIndex + 1;
      const getNextIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
      currentCharIndex = getNextIndex == -1 ? fabricObject._text.length : getNextIndex;
      const subArr = fabricObject._text.slice(startIndex, currentCharIndex);

      // if there are no charStyles for this line, initialize as empty charStyles
      if (!fabricObject.styles[lineIndex])
        fabricObject.styles[lineIndex] = {};
      if (!tempBeforeAnimate.styles[lineIndex])
        tempBeforeAnimate.styles[lineIndex] = {};

      subArr.forEach(async (c, charIndex) => {
        if(c == ' ') return;

        // if there are no charStyles for this character, initialize as empty charStyles
        if (!fabricObject.styles[lineIndex][charIndex])
          fabricObject.styles[lineIndex][charIndex] = {};
        if (!tempBeforeAnimate.styles[lineIndex][charIndex])
          tempBeforeAnimate.styles[lineIndex][charIndex] = {};

        // Gets the properties that need to be tweened
        let getStyles = {};
        let type = currentPosition == positions.start ? animation.start.type : currentPosition == positions.end ? animation.end.type : animation.middle.type;
        switch (currentPosition) {
          case positions.start:
            getStyles = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, true);
            break;
          case positions.end:
            getStyles = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, false);
            break;
        }

        // To one tween we can chain only one tween.
        // But in this case we to chain set of tweens to another set of tweens. Each tween in a set, changes one property('fill' / 'fontsize').
        // So we are creating one tween for each set, which onstart, starts all the tweens in a set.
        // The main tween which starts the set of tweens, uses the layer group
        // set tweens use another new group, if we use layer group they are updated to there last values
        var propertiesTweens = [];
        let tweenGroup = new TWEEN.Group();
        let startTime = isForwardAnimation ?
          layerStartTime + (i * (durationPerCharacter))
          : layerStartTime + (l * (durationPerCharacter))
        additionalGroups.push({ group: tweenGroup, startTime: startTime, endTime: startTime + durationPerCharacter })
        for (let propItr of getStyles.animateProp) {
          propertiesTweens.push(
            textAnimateUtil(
              {
                animateProp: propItr,
                fabricObject,
                lineIndex,
                charIndex,
                time: durationPerCharacter - decreaseDuration,
                canvas,
                styleObj: { onAnimate: getStyles.onAnimate, afterAnimate: getStyles.afterAnimate, easing }
              },
              tweenGroup,
              0)
          );
        }
        // AY TODO: Why forEachAsync?
        propertiesTweens.forEach(t => {
          t.repeat(loopCount == loopTypes.forever ? foreverLoop : loopCount - 1)
            .repeatDelay(loopDuration - durationPerCharacter - decreaseDuration)
        })
        let tweenIndex = isForwardAnimation ? i++ : l--;
        tweens[tweenIndex] = playTweens(timeline, propertiesTweens, durationPerCharacter, tweenGroup, loopDuration);
      });
      lineIndex++;
    } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline, tempBeforeAnimate));
    return tweens;
  },
  letterMiddleAnimationsHandler(fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, configs) {
    var tweens = [];
    var tempBeforeAnimate = {};
    tempBeforeAnimate.padding = 10;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    if (fabricObject._text.length != 0) {
      var time = duration;
      var arrSum = fabricObject._text.filter(e => e != ' ' && e != '\n').length;
      const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';
      let charAnimationCount = 1;
      let animateCounter = configs.count;

      // we need to animate twice to show the heartbeat, so reduce time by 2
      if (animation.middle.type == typeNames.heartBeat) {
        time /= 2;
        charAnimationCount = 2;
      }

      // we have two animations for fade and size so reduce the time by half
      if(animation.middle.type == typeNames.pulsate) {
        time /= 2;
      }

      // update time that's required for each char based on number of times we can animate
      time = time / (animateCounter * arrSum);
      // each middle animation contains usually 2 animations to perform, so we divide that by two
      time /= 2;
      for (var j = 0; j < animateCounter; j++) {
        var lineIndex = 0,
          currentCharIndex = -1;
        do {
          // if we animate based on _textLayer, animation won't work properly in some cases
          // so we split text property based on each new line(\n), so we can update styles array accordingly
          // each line in styles represents each lines in text layer
          // this way if a text is wrapped into next line because of less space in the layer - we consider it as same line
          const startIndex = currentCharIndex + 1;
          const getNextIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
          currentCharIndex = getNextIndex == -1 ? fabricObject._text.length : getNextIndex;
          const getSubArr = fabricObject._text.slice(startIndex, currentCharIndex);
          if (!tempBeforeAnimate.styles[lineIndex])
            tempBeforeAnimate.styles[lineIndex] = {};
          getSubArr.forEach((c, charIndex) => {
            if (c != ' ') {
              let getStyles = animationConfig.getMiddleStyleCharLevel(fabricObject, animation.middle.type, lineIndex, charIndex, animation.start.type, configs);
              if (!tempBeforeAnimate.styles[lineIndex][charIndex])
                tempBeforeAnimate.styles[lineIndex][charIndex] = {};
              for (let itr = 0; itr < charAnimationCount; itr++) {
                var localTweens = [];
                for (let propItr of getStyles.animateProp) {
                  localTweens.push(textAnimateUtil({
                    animateProp: propItr,
                    fabricObject,
                    lineIndex,
                    charIndex,
                    time,
                    canvas,
                    styleObj: { beforeAnimate: getStyles.onAnimate, easing }
                  }, timeline, 0, false));
                }
                if (!isForwardAnimation) {
                  var firstTween = localTweens[0];
                  localTweens[0] = localTweens[1];
                  localTweens[1] = firstTween;
                }
                tweens.push(...localTweens);
              }
            }
          });
          lineIndex++;
        } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);
      }
      if (!isForwardAnimation) tweens.reverse();
    }
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline, tempBeforeAnimate));
    return tweens;
  },
  wordUtilAnimationHandler(fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, additionalGroups, layerStartTime, loopCount, loopDuration, configs) {
    let lineIndex = 0,
      currentCharIndex = -1,
      tempBeforeAnimate = {};
    var tweens = [];
    tempBeforeAnimate.opacity = 1;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    tempBeforeAnimate.padding = 10;
    if (fabricObject._text.length != 0) {
      let wordIndices = [];
      const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';
      // we don't need whitespaces, tabs, new lines for animations into consideration as this is word level animation
      // we need to split the given animation duration into equal ratios, such that each word gets equal time
      // so we use wordIndices where line is lineIndex, start is startIndex of a character, and end is endIndex
      // the length of wordIndices is the number of words present in our text layer, regardless of lines present
      do {
        const startLineIndex = currentCharIndex + 1;
        const getNextLineIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
        currentCharIndex = getNextLineIndex == -1 ? fabricObject._text.length : getNextLineIndex;
        let getSubArr = fabricObject._text.slice(startLineIndex, currentCharIndex);
        let currentWordIndex = 0;
        do {
          while (getSubArr[currentWordIndex] == ' ' || getSubArr[currentWordIndex] == '\t') currentWordIndex++;
          const startWordIndex = currentWordIndex;
          const getWordEndIndex = getSubArr.indexOf(' ', currentWordIndex + 1);
          currentWordIndex = getWordEndIndex == -1 ? getSubArr.length : getWordEndIndex;
          // currentWordIndex > startWordIndex - this condition is needed when there's only whitespaces after a word
          if (currentWordIndex - 1 != -1 && currentWordIndex > startWordIndex)
            wordIndices.push({ line: lineIndex, start: startWordIndex, end: currentWordIndex - 1 });
        } while(getSubArr.indexOf(' ', currentWordIndex) != -1);
        lineIndex++;
      } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);

      let arrSum = wordIndices.length,
        time = duration;
      time /= arrSum;
      let getStyles = {};
      let propList = (currentPosition == positions.start) ?
        animationConfig.getAnimationPropList(animation.start.type) : animationConfig.getAnimationPropList(animation.end.type);
      wordIndices.forEach(eachIndex => {
        const lineIndex = eachIndex.line;
        if (!fabricObject.styles[lineIndex])
          fabricObject.styles[lineIndex] = {};
        if (!tempBeforeAnimate.styles[lineIndex])
          tempBeforeAnimate.styles[lineIndex] = {};
        if (!getStyles[lineIndex])
          getStyles[lineIndex] = {};
        for (let charIndex = eachIndex.start; charIndex <= eachIndex.end; charIndex++) {
          if (!fabricObject.styles[lineIndex][charIndex])
            fabricObject.styles[lineIndex][charIndex] = {};
          if (!tempBeforeAnimate.styles[lineIndex][charIndex])
            tempBeforeAnimate.styles[lineIndex][charIndex] = {};
          if (!getStyles[lineIndex][charIndex]) {
            getStyles[lineIndex][charIndex] = {};
            getStyles[lineIndex][charIndex].animateProp = {};
          }
          let getStylesTemp = {};
          let type = currentPosition == positions.start ? animation.start.type : currentPosition == positions.end ? animation.end.type : animation.middle.type;
          switch (currentPosition) {
            case positions.start:
              getStylesTemp = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, true);
              break;
            case positions.end:
              getStylesTemp = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, false);
              break;
          }
          getStylesTemp.animateProp.forEach(e => {getStyles[lineIndex][charIndex].animateProp[`${e.propName}`] = e});
        }
        tweens.push(animateWordLineUtil({
          charStyles: getStyles,
          propList,
          animationPosition: currentPosition,
          fabricObject,
          lineIndex,
          charStartIndex: eachIndex.start,
          charEndIndex: eachIndex.end,
          time,
          canvas,
          easing
        }, timeline, 0, currentPosition != positions.start));
      })
      if(!isForwardAnimation) { tweens.reverse(); }
    }
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline,  tempBeforeAnimate));
    return tweens;
  },
  wordMiddleAnimationsHandler(fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, configs) {
    let lineIndex = 0,
      currentCharIndex = -1,
      tweens = [],
      tempBeforeAnimate = {};
    tempBeforeAnimate.padding = 10;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    if (fabricObject._text.length != 0) {
      let wordIndices = [];
      const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';
      do {
        const startLineIndex = currentCharIndex + 1;
        const getNextLineIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
        currentCharIndex = getNextLineIndex == -1 ? fabricObject._text.length : getNextLineIndex;
        let getSubArr = fabricObject._text.slice(startLineIndex, currentCharIndex);
        let currentWordIndex = 0;
        do {
          while (getSubArr[currentWordIndex] == ' ' || getSubArr[currentWordIndex] == '\t') currentWordIndex++;
          const startWordIndex = currentWordIndex;
          const getWordEndIndex = getSubArr.indexOf(' ', currentWordIndex + 1);
          currentWordIndex = getWordEndIndex == -1 ? getSubArr.length : getWordEndIndex;
          if (currentWordIndex - 1 != -1 && currentWordIndex > startWordIndex)
            wordIndices.push({ line: lineIndex, start: startWordIndex, end: currentWordIndex - 1 });
        } while(getSubArr.indexOf(' ', currentWordIndex) != -1);
        lineIndex++;
      } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);

      let arrSum = wordIndices.length,
        time = duration;

      let charAnimationCount = 1;
      let animateCounter = configs.count;
      if (animation.middle.type == typeNames.heartBeat) {
        time /= 2;
        charAnimationCount = 2;
      }
      time = time / (animateCounter * arrSum);
      time /= 2;
      let getStyles = {};
      let propList = animationConfig.getAnimationPropList(animation.middle.type);
      for (var j = 0; j < animateCounter; j++) {
        wordIndices.forEach(eachIndex => {
          const lineIndex = eachIndex.line;
          if (!fabricObject.styles[lineIndex])
            fabricObject.styles[lineIndex] = {};
          if (!tempBeforeAnimate.styles[lineIndex])
            tempBeforeAnimate.styles[lineIndex] = {};
          if (!getStyles[lineIndex])
            getStyles[lineIndex] = {};
          for (let charIndex = eachIndex.start; charIndex <= eachIndex.end; charIndex++) {
            if (!fabricObject.styles[lineIndex][charIndex])
              fabricObject.styles[lineIndex][charIndex] = {};
            if (!tempBeforeAnimate.styles[lineIndex][charIndex])
              tempBeforeAnimate.styles[lineIndex][charIndex] = {};
            if (!getStyles[lineIndex][charIndex]) {
              getStyles[lineIndex][charIndex] = {};
              getStyles[lineIndex][charIndex].animateProp = {};
            }
            let getStylesTemp = animationConfig.getMiddleStyleCharLevel(fabricObject, animation.middle.type, lineIndex, charIndex, animation.start.type, configs);
            getStylesTemp.animateProp.forEach((e, propIndex) => {
              if (!getStyles[lineIndex][charIndex].animateProp[`${e.propName}`])
                getStyles[lineIndex][charIndex].animateProp[`${e.propName}`] = {};
              getStyles[lineIndex][charIndex].animateProp[`${e.propName}`][`${propIndex}`] = e;
            });
          }

          for (let itr = 0; itr < charAnimationCount; itr++) {
            var animationIndex = 0, backwardAnimationIndex = 1;
            for (let propItr of propList) {
              tweens.push(animateWordLineUtil({
                charStyles: getStyles,
                propName: propItr,
                animationPosition: currentPosition,
                fabricObject,
                lineIndex,
                animationIndex: isForwardAnimation ? animationIndex++ : backwardAnimationIndex--,
                charStartIndex: eachIndex.start,
                charEndIndex: eachIndex.end,
                time,
                canvas,
                easing
              }, timeline, 0, false));
            }
          }
        })
      }
      if(!isForwardAnimation) tweens.reverse();
    }
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline, tempBeforeAnimate));
    return tweens;
  },
  lineUtilAnimationHandler(fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, additionalGroups, layerStartTime, loopCount, loopDuration, configs) {
    let lineIndex = 0,
      currentCharIndex = -1,
      tempBeforeAnimate = {};
    var tweens = [];
    tempBeforeAnimate.opacity = 1;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    tempBeforeAnimate.padding = 10;
    if (fabricObject._text.length != 0) {
      let wordIndices = [];
      const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';
      do {
        const startLineIndex = currentCharIndex + 1;
        const getNextLineIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
        currentCharIndex = getNextLineIndex == -1 ? fabricObject._text.length : getNextLineIndex;
        let getSubArr = fabricObject._text.slice(startLineIndex, currentCharIndex);
        let currentWordIndex = 0;
        do {
          while (getSubArr[currentWordIndex] == ' ' || getSubArr[currentWordIndex] == '\t') currentWordIndex++;
          const startWordIndex = currentWordIndex;
          const getWordEndIndex = getSubArr.indexOf('\n', currentWordIndex + 1);
          currentWordIndex = getWordEndIndex == -1 ? getSubArr.length : getWordEndIndex;
          // we don't want whitespaces to be animated, so we loop to point currentWordIndex at a character that is not a whitespace
          while (getSubArr[currentWordIndex - 1] == ' ' || getSubArr[currentWordIndex - 1] == '\t') currentWordIndex--;
          if (currentWordIndex - 1 != -1 && currentWordIndex > startWordIndex)
            wordIndices.push({ line: lineIndex, start: startWordIndex, end: currentWordIndex - 1 });
        } while(getSubArr.indexOf('\n', currentWordIndex) != -1);
        lineIndex++;
      } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);

      let arrSum = wordIndices.length,
        time = duration;
      time /= arrSum;
      let getStyles = {};
      let propList = (currentPosition == positions.start) ?
        animationConfig.getAnimationPropList(animation.start.type) : animationConfig.getAnimationPropList(animation.end.type);
      wordIndices.forEach(eachIndex => {
        const lineIndex = eachIndex.line;
        if (!fabricObject.styles[lineIndex])
          fabricObject.styles[lineIndex] = {};
        if (!tempBeforeAnimate.styles[lineIndex])
          tempBeforeAnimate.styles[lineIndex] = {};
        if (!getStyles[lineIndex])
          getStyles[lineIndex] = {};
        for (let charIndex = eachIndex.start; charIndex <= eachIndex.end; charIndex++) {
          if (!fabricObject.styles[lineIndex][charIndex])
            fabricObject.styles[lineIndex][charIndex] = {};
          if (!tempBeforeAnimate.styles[lineIndex][charIndex])
            tempBeforeAnimate.styles[lineIndex][charIndex] = {};
          if (!getStyles[lineIndex][charIndex]) {
            getStyles[lineIndex][charIndex] = {};
            getStyles[lineIndex][charIndex].animateProp = {};
          }
          let getStylesTemp = {};
          let type = currentPosition == positions.start ? animation.start.type : currentPosition == positions.end ? animation.end.type : animation.middle.type;
          switch (currentPosition) {
            case positions.start:
              getStylesTemp = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, true);
              break;
            case positions.end:
              getStylesTemp = animationConfig.getStartAndEndStyleCharLevel(canvas, fabricObject, type, lineIndex, charIndex, tempBeforeAnimate, configs, false);
              break;
          }
          getStylesTemp.animateProp.forEach(e => {getStyles[lineIndex][charIndex].animateProp[`${e.propName}`] = e});
        }
        tweens.push(animateWordLineUtil({
          charStyles: getStyles,
          propList,
          animationPosition: currentPosition,
          fabricObject,
          lineIndex,
          charStartIndex: eachIndex.start,
          charEndIndex: eachIndex.end,
          time,
          canvas,
          easing
        }, timeline, 0, currentPosition != positions.start));
      });
      if(!isForwardAnimation)  { tweens.reverse(); }
    }
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline, tempBeforeAnimate));
    return tweens;
  },
  lineMiddleAnimationsHandler(fabricObject, basetime, duration, canvas, animation, currentPosition, timeline, easing, configs) {
    let lineIndex = 0,
      currentCharIndex = -1,
      tempBeforeAnimate = {};
    var tweens = [];
    tempBeforeAnimate.padding = 10;
    tempBeforeAnimate.styles = JSON.parse(JSON.stringify(fabricObject.styles));
    if (fabricObject._text.length != 0) {
      let wordIndices = [];

      const isForwardAnimation = animation[currentPosition].animationDirection && animation[currentPosition].animationDirection == 'forward';

      do {
        const startLineIndex = currentCharIndex + 1;
        const getNextLineIndex = fabricObject._text.indexOf('\n', currentCharIndex + 1);
        currentCharIndex = getNextLineIndex == -1 ? fabricObject._text.length : getNextLineIndex;
        let getSubArr = fabricObject._text.slice(startLineIndex, currentCharIndex);
        let currentWordIndex = 0;
        do {
          while (getSubArr[currentWordIndex] == ' ' || getSubArr[currentWordIndex] == '\t') currentWordIndex++;
          const startWordIndex = currentWordIndex;
          const getWordEndIndex = getSubArr.indexOf('\n', currentWordIndex + 1);
          currentWordIndex = getWordEndIndex == -1 ? getSubArr.length : getWordEndIndex;
          while (getSubArr[currentWordIndex - 1] == ' ' || getSubArr[currentWordIndex - 1] == '\t') currentWordIndex--;
          if (currentWordIndex - 1 != -1 && currentWordIndex > startWordIndex)
            wordIndices.push({ line: lineIndex, start: startWordIndex, end: currentWordIndex - 1 });
        } while(getSubArr.indexOf('\n', currentWordIndex) != -1);
        lineIndex++;
      } while (fabricObject._text.indexOf('\n', currentCharIndex) != -1);
      let arrSum = wordIndices.length,
        time = duration;
      let charAnimationCount = 1;
      let animateCounter = configs.count;
      if (animation.middle.type == typeNames.heartBeat) {
        time /= 2;
        charAnimationCount = 2;
      }
      time = time / (animateCounter * arrSum);
      time /= 2;
      let getStyles = {};
      let propList = animationConfig.getAnimationPropList(animation.middle.type);
      for (var j = 0; j < animateCounter; j++) {
        wordIndices.forEach(eachIndex => {
          const lineIndex = eachIndex.line;
          if (!fabricObject.styles[lineIndex])
            fabricObject.styles[lineIndex] = {};
          if (!tempBeforeAnimate.styles[lineIndex])
            tempBeforeAnimate.styles[lineIndex] = {};
          if (!getStyles[lineIndex])
            getStyles[lineIndex] = {};
          for (let charIndex = eachIndex.start; charIndex <= eachIndex.end; charIndex++) {
            if (!fabricObject.styles[lineIndex][charIndex])
              fabricObject.styles[lineIndex][charIndex] = {};
            if (!tempBeforeAnimate.styles[lineIndex][charIndex])
              tempBeforeAnimate.styles[lineIndex][charIndex] = {};
            if (!getStyles[lineIndex][charIndex]) {
              getStyles[lineIndex][charIndex] = {};
              getStyles[lineIndex][charIndex].animateProp = {};
            }
            let getStylesTemp = animationConfig.getMiddleStyleCharLevel(fabricObject, animation.middle.type, lineIndex, charIndex, animation.start.type, configs);
            getStylesTemp.animateProp.forEach((e, propIndex) => {
              if (!getStyles[lineIndex][charIndex].animateProp[`${e.propName}`])
                getStyles[lineIndex][charIndex].animateProp[`${e.propName}`] = {};
              getStyles[lineIndex][charIndex].animateProp[`${e.propName}`][`${propIndex}`] = e;
            });
          }

          for (let itr = 0; itr < charAnimationCount; itr++) {
            var animationIndex = 0, backwardAnimationIndex = 1;
            for (let propItr of propList) {
              tweens.push(animateWordLineUtil({
                charStyles: getStyles,
                propName: propItr,
                animationPosition: currentPosition,
                fabricObject,
                lineIndex,
                animationIndex: isForwardAnimation ? animationIndex++ : backwardAnimationIndex--,
                charStartIndex: eachIndex.start,
                charEndIndex: eachIndex.end,
                time,
                canvas,
                easing
              }, timeline, 0, false));
            }
          }
        })
      }
      if (!isForwardAnimation) tweens.reverse();
    }
    tweens.unshift(tweenGenerator.changeFabricObject(basetime, fabricObject, timeline, tempBeforeAnimate));
    return tweens;
  },
  animateFillCharLevel(payload, newValue) {
    payload.fabricObject.styles[payload.lineIndex][payload.charIndex].fill = payload.animateProp.HexArray.length > 0 ? `rgba(${payload.animateProp.HexArray.toString()},${newValue})` : 1;
  },
  animateFillWordLineLevel(fabricObject, lineIndex, charIndex, hexArray, newValue) {
    fabricObject.styles[lineIndex][charIndex].fill = hexArray.length > 0 ? `rgba(${hexArray.toString()},${newValue})` : 1;
  },
  getTextRGB(fabricObject, lineIndex, charIndex) {
    if (fabricObject.styles[lineIndex] && fabricObject.styles[lineIndex][charIndex] && fabricObject.styles[lineIndex][charIndex].fill) {
      return Color.fromRgba(fabricObject.styles[lineIndex][charIndex].fill)._source.slice(0, 3);
    }
    if (!fabricObject.fill)
      return [];
    if (typeof(fabricObject.fill) == 'string' && fabricObject.fill.indexOf('rgb') != -1)
      return Color.fromRgba(fabricObject.fill)._source.slice(0, 3);
    if (typeof(fabricObject.fill) == 'object')
      return [];
    const getColor = Color.fromHex(fabricObject.fill)._source;
    return getColor ? getColor.slice(0, 3) : [];
  },
  setAnimateProp(animateProp, index, startValue, endValue, typeName, propName, hexArray = []) {
    animateProp[index] = {};
    animateProp[index].startValue = startValue;
    animateProp[index].endValue = endValue
    animateProp[index].typeName = typeName;
    animateProp[index].propName = propName;
    animateProp[index].HexArray =  hexArray;
  },
  setDefaultProp(prop, propName, value, opacity = 0) {
    if (propName == stylesProperties.fill) {
      prop[propName] = value.length > 0 ? `rgba(${value.toString()},${opacity})` : 1
    }
    else
      prop[propName] = value;
  },
  getRequestedStylesProp(prop, propName, lineIndex, charIndex) {
    switch(propName) {
      case stylesProperties.fontSize: {
        return prop.styles[lineIndex][charIndex][propName] ? prop.styles[lineIndex][charIndex][propName] : prop.fontSize;
      }
      case stylesProperties.fill: {
        if (!prop.styles[lineIndex] || !prop.styles[lineIndex][charIndex] || !prop.styles[lineIndex][charIndex][propName]) {
          if (prop.fill.indexOf('rgba') != -1) {
            const rgbaArr = prop.fill.replaceAll('rgba', '').split(',');
            return parseFloat(rgbaArr[rgbaArr.length - 1].replace(')', ''));
          } else if (prop.fill.indexOf('#') != -1) {
            const rgbaArr = Color.fromHex(prop.fill)._source;
            return rgbaArr[rgbaArr.length - 1];
          }
          return prop.opacity;
        }
        if (!prop.styles[lineIndex][charIndex][propName] || prop.styles[lineIndex][charIndex][propName].indexOf('rgba') == -1) {
          return prop.opacity
        }
        const rgbaString = prop.styles[lineIndex][charIndex][propName]
        const rgbaArr = rgbaString.replaceAll('rgba', '').split(',')
        return parseFloat(rgbaArr[rgbaArr.length - 1].replace(')', ''));
      }
      default: {
        const getPropValue = prop.styles[lineIndex][charIndex][propName];
        return getPropValue ? getPropValue : 0;
      }
    }
  },
  textWrapHandler(fabricObject, canvas) {
    var newLineCount = fabricObject.text.split('\n').length;
    var accumulate = -1;
    if (newLineCount != fabricObject._textLines.length) {
      fabricObject._textLines.forEach(e => {
        accumulate += e.length + 1;
        if (fabricObject.text[accumulate] == ' ') {
          fabricObject.set({ text: fabricObject.text.substr(0, accumulate) + '\n' + fabricObject.text.substr(accumulate + 1, fabricObject.text.length) });
        }
      });
      canvas.renderAll();
    }
  }
}

/**
 * Defines the properties which need to be tweened.
 * ex: fade animation => fill property's alpha needs to be tweened.
 * @type {Object}
 */
const animationConfig = {
  getStartAndEndStyleCharLevel(canvas, fabricObject, typeName, lineIndex, charIndex, beforeAnimate, configs, isStart) {
    let animateProp = [];
    let itr = 0;
    let onAnimate = {};
    let afterAnimate = {};
    afterAnimate[lineIndex] = {};
    afterAnimate[lineIndex][charIndex] = {};
    onAnimate[lineIndex] = {};
    onAnimate[lineIndex][charIndex] = {};
    switch (typeName) {
      case typeNames.fade: {
        const fadeStart = configs.fadeStart == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeStart) / 100) ;
        const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeEnd) / 100);
        const hexArray = genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, fadeStart, fadeEnd, typeNames.fade, stylesProperties.fill, hexArray);
        isStart && (beforeAnimate.styles[lineIndex][charIndex].fill = hexArray.length > 0 ? `rgba(${hexArray.toString()},0)` : 0);
        break;
      }
      case typeNames.slideVertical: {
        let slideStart = configs.slideStart == currentAnimationConfigValue ? fabricObject.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
        let slideEnd = configs.slideEnd == currentAnimationConfigValue ? fabricObject.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
        slideStart -= fabricObject.top;
        slideEnd -= fabricObject.top;
        const hexArray = genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr, slideStart, slideEnd, typeNames.slideUp, stylesProperties.deltaY);
        if(isStart) beforeAnimate.styles[lineIndex][charIndex].deltaY = slideStart
        else afterAnimate[lineIndex][charIndex].fill = hexArray.length > 0 ? `rgba(${hexArray.toString()}, 0)` : 0;
        break;
      }
      case typeNames.fadeSlideVertical: {
        const hexArray =  genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        let slideStart = configs.slideStart == currentAnimationConfigValue ? fabricObject.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
        let slideEnd = configs.slideEnd == currentAnimationConfigValue ? fabricObject.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
        slideStart -= fabricObject.top;
        slideEnd -= fabricObject.top;
        const fadeStart = configs.fadeStart == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeStart) / 100) ;
        const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeEnd) / 100);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, slideStart, slideEnd, typeName, stylesProperties.deltaY, hexArray);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, fadeStart, fadeEnd, typeName, stylesProperties.fill, hexArray);
        beforeAnimate.styles[lineIndex][charIndex].deltaY = slideStart;
        if(isStart) {
          beforeAnimate.styles[lineIndex][charIndex].fill = hexArray.length > 0 ? `rgba(${hexArray.toString()},${fadeStart})` : 0;
        }
        break;
      }
      case typeNames.scale: {
        const scaleStart = configs.scaleStart == currentAnimationConfigValue ? fabricObject.fontSize : (Math.ceil(configs.scaleStart) / 100) * fabricObject.fontSize;
        const scaleEnd = configs.scaleEnd == currentAnimationConfigValue ? fabricObject.fontSize : (Math.ceil(configs.scaleEnd) / 100) * fabricObject.fontSize;
        genericUtilFunctions.setAnimateProp(animateProp, itr++, scaleStart, scaleEnd, typeName, stylesProperties.fontSize);
        beforeAnimate.styles[lineIndex][charIndex].fontSize = scaleStart;
        break;
      }
      case typeNames.typeWriter: {
        const endValue = genericUtilFunctions.getRequestedStylesProp(fabricObject, stylesProperties.fontSize, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, 0, endValue, typeName, stylesProperties.fontSize);
        const opacity = genericUtilFunctions.getRequestedStylesProp(fabricObject, stylesProperties.fill, lineIndex, charIndex);
        const hexArray = genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, 0, opacity, typeNames.fade, stylesProperties.fill, hexArray);
        beforeAnimate.styles[lineIndex][charIndex].fill = hexArray.length > 0 ? `rgba(${hexArray.toString()},0)` : 0;
        beforeAnimate.styles[lineIndex][charIndex].fontSize = 0;
        break;
      }
    }
    return { animateProp, onAnimate, afterAnimate };
  },
  getMiddleStyleCharLevel(fabricObject, typeName, lineIndex, charIndex, startTypeName = typeNames.none, configs) {
    let animateProp = [];
    let itr = 0;
    let startValue = fabricObject.fontSize;
    switch (typeName) {
      case typeNames.pulsate: {
        const scaleStart = configs.scaleStart == currentAnimationConfigValue ? fabricObject.fontSize : (Math.ceil(configs.scaleStart) / 100) * fabricObject.fontSize;
        const scaleEnd = configs.scaleEnd == currentAnimationConfigValue ? fabricObject.fontSize : (Math.ceil(configs.scaleEnd) / 100) * fabricObject.fontSize;
        const fadeStart = configs.fadeStart == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeStart) / 100) ;
        const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? fabricObject.opacity : (Math.ceil(configs.fadeEnd) / 100);
        const hexArray =  genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, scaleStart, scaleEnd, typeName, stylesProperties.fontSize);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, scaleEnd, scaleStart, typeName, stylesProperties.fontSize);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, fadeStart, fadeEnd, typeName, stylesProperties.fill, hexArray);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, fadeEnd, fadeStart, typeName, stylesProperties.fill, hexArray);
        break;
      }
      case typeNames.blink: {
        const hexArray =  genericUtilFunctions.getTextRGB(fabricObject, lineIndex, charIndex);
        const opacity = genericUtilFunctions.getRequestedStylesProp(fabricObject, stylesProperties.fill, lineIndex, charIndex);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, opacity, animateConstants.minOpacity, typeName, stylesProperties.fill, hexArray);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, animateConstants.minOpacity, opacity, typeName, stylesProperties.fill, hexArray);
        break;
      }
      case typeNames.heartBeat: {
        const endValue = startValue * animateConstants.fontSizeScale;
        genericUtilFunctions.setAnimateProp(animateProp, itr++, startValue, endValue, typeName, stylesProperties.fontSize);
        genericUtilFunctions.setAnimateProp(animateProp, itr++, endValue, startValue, typeName, stylesProperties.fontSize);
        break;
      }
    }
    return { animateProp };
  },
  getAnimationPropList(propName) {
    switch(propName) {
      case typeNames.fade: {
        return [stylesProperties.fill];
      }
      case typeNames.slideVertical: {
        return [stylesProperties.deltaY];
      }
      case typeNames.fadeSlideVertical: {
        return [stylesProperties.fill, stylesProperties.deltaY];
      }
      case typeNames.scale: {
        return [stylesProperties.fontSize];
      }
      case typeNames.typeWriter: {
        return [stylesProperties.fontSize, stylesProperties.fill]
      }
      case typeNames.pulsate: {
        return [stylesProperties.fontSize, stylesProperties.fontSize];
      }
      case typeNames.blink: {
        return [stylesProperties.fill, stylesProperties.fill];
      }
      case typeNames.heartBeat: {
        return [stylesProperties.fontSize, stylesProperties.fontSize];
      }
    }
  }
}

/**
 * Used in animationMutations.updateAnimations - modifies the animation state's type/timings
 * @param {*} animation
 * @param {*} data
 */
const updateLayerAnimation = function (animation, animationBackup, data) {
  if (data.update == animationUpdateTypes.ANIMATION_POSITION) { //updates animation timings/type
    let configs = animation[data.position]?.configs;
    if(configs) {
      let animationType = animation[data.position].type;
      if(animationType == typeNames.none || animationType == typeNames.typeWriter) {
        configs = {};
      }
      else {
        let positionAnimation = animationPreviewConstants[data.position]
        let supportedConfigs = positionAnimation[animationType]
        let supportedConfigKeys = Object.keys(supportedConfigs);
        Object.keys(configs).forEach(conf => {
          if(!supportedConfigKeys.includes(conf)) {
            delete configs[conf];
          }
        });
      }
    }
    deepAssign(animation[data.position], data.properties);
    if (animationBackup) {
      animationBackup[data.position].startTime = data.properties.startTime ?? animationBackup[data.position].startTime;
      animationBackup[data.position].endTime = data.properties.endTime ?? animationBackup[data.position].endTime;
    }
  }
  else if (data.update == animationUpdateTypes.LAYER) { //updates layer timings
    deepAssign(animation, data.properties);
    if (animationBackup) {
      animationBackup.layerStartTime = data.properties.layerStartTime ?? animationBackup.layerStartTime;
      animationBackup.layerEndTime = data.properties.layerEndTime ?? animationBackup.layerEndTime;
    }
  }
  else if(data.update == animationUpdateTypes.ANIMATION_SOURCE_POSITION) //updates animation source timings/type for multiple sources layer.
    deepAssign(animation.sources[data.sourceIndex][data.position], data.properties);
  //Update default animation for multiple sources
  if (animation.sources) {
    animation.sources.forEach(source => {
      if (!source.isDirty) {
        deepAssign(animation.default, { start: source.start, middle: source.middle, end: source.end });
        return;
      }
    })
  }
}

/**
 * Updated the static layer animations timings to max timings of dynamic layer animation
 * @param {*} animations
 * @param {*} layers
 */
export const updateStaticLayerAnimations = function (animations, layers) {
  let maxLayerEndTime = 0;
  let maxEndTimeAnimation;
  layers.filter(layer => layer.animationLayerType != animationLayerTypes.static).forEach(layer => {
    const animation = animations.find(a => a.layerId == layer.id);
    if (animation.layerEndTime > maxLayerEndTime) {
      maxLayerEndTime = animation.layerEndTime;
      maxEndTimeAnimation = animation;
    }
  });
  layers.filter(layer => layer.animationLayerType == animationLayerTypes.static).forEach(layer => {
    const animation = animations.find(a => a.layerId == layer.id);
    if (animation && maxEndTimeAnimation) {
      // Update start, middle and end animations start and end time
      animation.start.startTime = maxEndTimeAnimation.start.startTime ?? animation.start.startTime;
      animation.start.endTime = maxEndTimeAnimation.start.endTime ?? animation.start.endTime;

      animation.middle.startTime = maxEndTimeAnimation.middle.startTime ?? animation.middle.startTime;
      animation.middle.endTime = maxEndTimeAnimation.middle.endTime ?? animation.middle.endTime;

      animation.end.startTime = maxEndTimeAnimation.end.startTime ?? animation.end.startTime;
      animation.end.endTime = maxEndTimeAnimation.end.endTime ?? animation.end.endTime;

      animation.layerEndTime = maxEndTimeAnimation.layerEndTime ?? animation.layerEndTime;
    }
  })
}

/**
 * Sets up the tweens and then runs them
 * @param {*} sliderTween
 * @param {*} tweenGroup
 * @param {*} timelineTime
 * @param {*} ad
 * @param {*} downloadConfig
 * @param {*} state
 * @param {*} loopCount
 * @param {*} rafRequests
 * @param {*} resetGroup
 * @param {*} allLoopsDuration
 * @param {*} fileName
 * @param {*} mediaUrls
 * @param {*} videoArrays
 * @param {*} builder
 * @param {*} renderOptions
 */
// AY TODO: Function is too complex - too many args
var callAnimationFunctions = async function(sliderTween, tweenGroup, timelineTime, ad, downloadConfig, state, loopCount, rafRequests, resetGroup, allLoopsDuration, fileName, animations, mediaUrls, videoArrays, builder, renderOptions) {
  let adHtmlCanvas = null;
  if(downloadConfig.loopCount && !downloadConfig.isMediaRecorder) {
    adHtmlCanvas = getAdHtmlCanvas(ad);
  }
  // reset fabric objects on last loop end.
  let o1 = { value: TWEEN_START_VALUE };
  var resetPromiseResolve;
  // Promise will be added to reset tween callback since it is an sync function and we need to await before we finish
  // the animation. Will be resolved on callback completion.
  var resetPromise = new Promise((resolve) => {
    resetPromiseResolve = resolve;
  });
  var resetTween = new TWEEN.Tween(o1, resetGroup).to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
    .onUpdate(async function(){
      if (builder) {
        builder.stop();
      }
      rafRequests.forEach(x => window.cancelAnimationFrame(x));
      await ad.layers.forEachAsync(async layer => {
        let styleObj = pick(layer.fabricObjectBackUp, resetProperties);
        const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
        if (layer.type == layerTypes.text && computedLayer.data.filters.shadow.enabled) {
          layer.fabricObject.objectCaching = true;
          styleObj.styles = JSON.parse(JSON.stringify(layer.fabricObjectBackUp.styles));
        }
        Object.assign(layer.fabricObject, styleObj);
        if (layer.type == layerTypes.image && computedLayer.data.multipleSources) {
          let sourceToUpdate = computedLayer.data.props.sources[0];
          await updateSourceOnCanvas({ ad, layerToUpdate: layer, data: computedLayer.data, source: sourceToUpdate })
        }
      });
      removeChildFabricObjects(ad);
      ad.canvas.renderAll();
      // Reset audio/video elements
      await ad.layers.forEachAsync(async layer => {
        if (layer.type == layerTypes.audio || layer.type == layerTypes.video) {
          await resetMedia(ad, layer, videoArrays, downloadConfig.loopCount, state.layers);
          ad.canvas.renderAll();
        }
      });
      tweenGroup.group.removeAll();
      tweenGroup.additionalGroups.forEach(y => y.group.removeAll());
      resetGroup.removeAll();
      if(downloadConfig.loopCount){
        var payload = { ad, videoBlobs: state.videoBlobs, isMediaRecorder: downloadConfig.isMediaRecorder, format: downloadConfig.format, fileName, extension: renderOptions.extension ? renderOptions.extension : '.mp4' };
        if (!downloadConfig.isMediaRecorder) {
          payload = { ...payload, mediaUrls, allLoopsDuration, builder, renderOptions, loopCount: downloadConfig.loopCount };
        }
        await stopRecording(payload);
      }
      resetPromiseResolve();
    })
    .delay(allLoopsDuration + lastResetWait)

  var chainBreakTween;
  // remove last tween during last loop, last tween: resets fabric object by always keeping opacity value as animateConstants.minOpacity.
  if(loopCount > 1) {
    let o = { value: TWEEN_START_VALUE };
    chainBreakTween = new TWEEN.Tween(o, resetGroup).to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
      .onUpdate(function(){
        tweenGroup.tweenLayers.forEach(x => {
          var l = x.tweens.length;
          if(l > 1 && loopCount > 1) {
            x.tweens[l - 2].chain();
          }
        });
      })
      .delay((ad.maxAdEndTime * (loopCount - 1) + minimumDuration) * 1000)
      .start(0);
  }

  tweenGroup.tweenLayers.forEach(x => {
    if(x.tweens.length > 0) x.tweens[0].start(0);
    if(x.parallelTween) x.parallelTween.start(0)
  });
  if(sliderTween) sliderTween.start(0);
  if(chainBreakTween) chainBreakTween.start(0);
  resetTween.start(0);
  var startTime = Date.now();
  if (downloadConfig.loopCount) {
    backupVideoElements(ad.layers);
  }
  await renderAnimations(
    rafRequests,
    startTime,
    timelineTime,
    tweenGroup,
    resetGroup,
    ad,
    downloadConfig,
    allLoopsDuration,
    adHtmlCanvas,
    videoArrays,
    builder,
    renderOptions,
    animations,
    state
  );
  await resetPromise;
}

/**
 * Seems like it will return a tween object that can be used to "play"
 * @param {*} timeline
 * @param {*} tweens
 * @param {*} time
 * @param {*} group
 * @param {*} loopTime
 * @returns TWEEN.Tween
 */
const playTweens = function(timeline, tweens, time, group, loopTime) {
  var o = { value: TWEEN_START_VALUE };
  var starttime = 0;
  return new TWEEN.Tween(o, timeline)
    .to({ value: time }, time)
    .onStart(function(){
      if(starttime == 0)
        tweens.forEachAsync(x=> x.start(0));
    })
    .onUpdate(function(object){
      group.update(starttime + object.value);
    })
    .onComplete(function(){
      starttime += loopTime
    })
    .delay(0)
}

// AY TODO: Deprecated / irrelevant code - would be better to abstract the "capturer" and "renderer". Adapted from https://stackoverflow.com/a/58969196
// Promise based timer
function wait(ms) {
  return new Promise(res => setTimeout(res, ms));
}

// Implementation for browser media recorder.
// Since it is not being used anymore, commenting out for now.
// Keeping it because we may implement media recorder as an encoder.

// implements a sub-optimal monkey-patch for requestPostAnimationFrame
// see https://stackoverflow.com/a/57549862/3702797 for details
// if (!window.requestPostAnimationFrame) {
//   window.requestPostAnimationFrame = function monkey(fn) {
//     const channel = new MessageChannel();
//     channel.port2.onmessage = evt => fn(evt.data);
//     requestAnimationFrame((t) => channel.port1.postMessage(t));
//   };
// }
// Promisifies EventTarget.addEventListener
function waitForEvent(target, type) {
  return new Promise((res) => target.addEventListener(type, res, {
    once: true
  }));
}
class FrameByFrameCanvasRecorder {
  constructor(sourceCanvas, FPS = 30, aStream, endTime) {
    this.endTime = endTime;
    this.FPS = FPS;
    this.source = sourceCanvas;
    const canvas = this.canvas = sourceCanvas.cloneNode();
    const ctx = this.drawingContext = canvas.getContext('2d');

    // we need to draw something on our canvas
    ctx.drawImage(sourceCanvas, 0, 0);
    const stream = this.stream = canvas.captureStream(0);
    const track = this.track = stream.getVideoTracks()[0];

    // Firefox still uses a non-standard CanvasCaptureMediaStream
    // instead of CanvasCaptureMediaStreamTrack
    if (!track.requestFrame) {
      track.requestFrame = () => stream.requestFrame();
    }
    if(aStream != null)
      stream.addTrack(aStream.getAudioTracks()[0]);

    // prepare our MediaRecorder
    const rec = this.recorder = new MediaRecorder(stream, { videoBitsPerSecond: 4500000, mimeType: 'video/webm;codecs=h264' });
    const chunks = this.chunks = [];
    rec.ondataavailable = (evt) => {
      chunks.push(evt.data)
    };
    rec.start();
    // we need to be in 'paused' state
    waitForEvent(rec, 'start')
      .then((evt) => rec.pause());
    // expose a Promise for when it's done
    this._init = waitForEvent(rec, 'pause');
  }
  async recordFrame() {
    await this._init; // we have to wait for the recorder to be paused
    const rec = this.recorder;
    const canvas = this.canvas;
    const source = this.source;
    const ctx = this.drawingContext;
    if (canvas.width !== source.width ||
       canvas.height !== source.height) {
      canvas.width = source.width;
      canvas.height = source.height;
    }

    // start our timer now so whatever happens between is not taken in account
    const timer = wait(1000 / this.FPS);

    // wake up the recorder
    if(rec.state != 'inactive')
    {
      rec.resume();
      await waitForEvent(rec, 'resume');
    }

    // draw the current state of source on our internal canvas (triggers requestFrame in Chrome)
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(source, 0, 0);
    // force write the frame
    this.track.requestFrame();

    // wait until our frame-time elapsed
    await timer;

    // sleep recorder
    if(rec.state != 'inactive')
    {
      rec.pause();
      await waitForEvent(rec, 'pause');
    }
  }
  async export () {
    this.recorder.stop();
    this.stream.getTracks().forEach((track) => track.stop());
    await waitForEvent(this.recorder, 'stop');

    let webmBlob = new Blob(this.chunks, { type: 'video/webm;codecs=h264' });
    let mp4Blob = null;

    let isMp4ConversionOk = false;

    try {
      let stdout = '';
      let stderr = '';

      let webmBlobArrayBuffer = await webmBlob.arrayBuffer();
      let webmBlobUint8Array = new Uint8Array(webmBlobArrayBuffer);
      const ffmpegMP4 = await import('ffmpeg.js/ffmpeg-mp4.js');
      let mp4 = ffmpegMP4({
        MEMFS: [{ name: 'in.webm', data: webmBlobUint8Array }],
        arguments: ['-loglevel', 'warning', '-ss', '0', '-t', `${this.endTime}`, '-i', 'in.webm', '-c:a', 'aac', '-vcodec', 'copy', '-strict', '-2', 'out.mp4'],
        print: function(data) { stdout += data + '\n'; },
        printErr: function(data) { stderr += data + '\n'; },
        onExit: function(code) {
          // eslint-disable-next-line
           console.log('Process exited with code ' + code);
          // eslint-disable-next-line
           console.log(stdout);
          // eslint-disable-next-line
           console.log(stderr);
        }
      });

      if(!mp4.MEMFS[0]) {
        throw new Error('Could not convert video to mp4 :(')
      }

      mp4Blob = new Blob([mp4.MEMFS[0].data], { type: 'video/mp4' });
      isMp4ConversionOk = true;
    } catch (error) {
      // eslint-disable-next-line
       console.error(error);
      isMp4ConversionOk = false;
    }
    if(isMp4ConversionOk && mp4Blob) {
      return mp4Blob;
    }
    return webmBlob;
  }
}

/**
 * Tweaks the border layer's opacity by an insignificant amount - probably no longer relevant
 * @param {*} ad
 */
const changeBorderLayerOpacity = function(ad) {
  var fabricObject = ad.layers.find(x => x.type == layerTypes.border).fabricObject;
  var addOpacity = fabricObject.opacity < animateConstants.maxOpacity - download.minOpacityChange ? download.minOpacityChange : -download.minOpacityChange;
  Object.assign(fabricObject, JSON.parse(JSON.stringify({ opacity: fabricObject.opacity + addOpacity })));
  ad.canvas.renderAll();
  fabricObject.opacity -= addOpacity;
}

/**
   * Generic tweening util which adds payload.animateProp into the timeline
   * Internally also sets up the handlers - onStart, onUpdate, onComplete
   * @param {*} payload
   * @param {*} timeline
   * @param {*} delay
   * @returns
   */
function textAnimateUtil(payload, timeline, delay) {
  var o = { value: payload.animateProp.startValue }
  return new TWEEN.Tween(o, timeline)
    .to({ value: payload.animateProp.endValue }, payload.time)
    .onStart(function(){
      if (payload.styleObj.onAnimate)
        Object.assign(payload.fabricObject.styles[payload.lineIndex][payload.charIndex], payload.styleObj.onAnimate[payload.lineIndex][payload.charIndex]);
    })
    .onUpdate(function(object) {
      var newVal = object.value || 0;
      if (payload.animateProp.propName && payload.fabricObject.styles[payload.lineIndex] && payload.fabricObject.styles[payload.lineIndex][payload.charIndex]) {
        if (payload.animateProp.propName == stylesProperties.fill) genericUtilFunctions.animateFillCharLevel(payload, newVal);
        else payload.fabricObject.styles[payload.lineIndex][payload.charIndex][payload.animateProp.propName] = newVal;
        payload.canvas.renderAll();
      }
    })
    .onComplete(function() {
      if (payload.styleObj.afterAnimate && payload.fabricObject.styles && payload.fabricObject.styles[payload.lineIndex] && payload.fabricObject.styles[payload.lineIndex][payload.charIndex]) {
        Object.assign(payload.fabricObject.styles[payload.lineIndex][payload.charIndex], payload.styleObj.afterAnimate[payload.lineIndex][payload.charIndex]);
        payload.canvas.renderAll();
      }
    })
    .delay(delay)
    .easing(TWEEN.Easing[payload.styleObj.easing.ease][payload.styleObj.easing.type])
}

/**
 * Util function that helps with animating of text layer - word-wise or line-wise
 * @param {*} payload
 * @param {*} timeline
 * @param {*} delay
 * @returns
 */
function animateWordLineUtil (payload, timeline, delay) {
  var o = { value: TWEEN_START_VALUE };
  return new TWEEN.Tween(o, timeline).to({ value: TWEEN_END_VALUE }, payload.time)
    .onUpdate(function(object){
      var newVal = object.value;
      if (payload.animationPosition == positions.start || payload.animationPosition == positions.end) {
        for (let charIndex = payload.charStartIndex; charIndex <= payload.charEndIndex; charIndex++) {
          // propList contains all the animation names we need to perform at some time
          // so we loop throught the list of animations and update accordingly
          for (let propItr of payload.propList) {
            if (payload.fabricObject.styles[payload.lineIndex][charIndex]) {
              const isEndAnimation = payload.animationPosition == positions.end ? true : false;
              let initVal = payload.charStyles[payload.lineIndex][charIndex].animateProp[propItr]['startValue'];
              let endVal = payload.charStyles[payload.lineIndex][charIndex].animateProp[propItr]['endValue'];
              if (propItr == stylesProperties.fill) {
                const finalVal = isEndAnimation ? initVal - Math.abs(initVal - endVal) * newVal : initVal + Math.abs(initVal - endVal) * newVal;
                genericUtilFunctions.animateFillWordLineLevel(payload.fabricObject, payload.lineIndex, charIndex, payload.charStyles[payload.lineIndex][charIndex].animateProp[propItr].HexArray, finalVal);
              } else {
                const isDecreasing = initVal > endVal ? true : false;
                const finalVal = isDecreasing ? (initVal - Math.abs(initVal - endVal) * newVal) : (initVal + Math.abs(initVal - endVal) * newVal);
                payload.fabricObject.styles[payload.lineIndex][charIndex][propItr] = finalVal;
              }
            }
          }
        }
      } else {
        for (let charIndex = payload.charStartIndex; charIndex <= payload.charEndIndex; charIndex++) {
          if (payload.fabricObject.styles[payload.lineIndex][charIndex]) {
            let initVal = payload.charStyles[payload.lineIndex][charIndex].animateProp[payload.propName][payload.animationIndex]['startValue'];
            let endVal = payload.charStyles[payload.lineIndex][charIndex].animateProp[payload.propName][payload.animationIndex]['endValue'];
            if (payload.propName == stylesProperties.fill) {
              const finalVal = initVal + Math.abs(initVal - endVal) * newVal;
              genericUtilFunctions.animateFillWordLineLevel(payload.fabricObject, payload.lineIndex, charIndex, payload.charStyles[payload.lineIndex][charIndex].animateProp[payload.propName][payload.animationIndex].HexArray, finalVal);
            } else {
              const isDecreasing = initVal > endVal ? true : false;
              const finalVal = isDecreasing ? (initVal - Math.abs(initVal - endVal) * newVal) : (initVal + Math.abs(initVal - endVal) * newVal);
              payload.fabricObject.styles[payload.lineIndex][charIndex][payload.propName] = finalVal;
            }
          }
        }
      }
      payload.canvas.renderAll();
    })
    .delay(delay)
    .easing(TWEEN.Easing[payload.easing.ease][payload.easing.type])
}

/**
   * Stops the canvas canvas capture, and exports the output
   * Output is pushed into payload.videoBlobs[] and then resolve() is invoked.
   * There is a watch on payload.videoBlobs - which then handles zipping/downloading
   * @param {*} payload
   */
// AY TODO: will be good to make payload a strongly-typed object
async function stopRecording(payload) {
  var ad = payload.ad;
  // AY TODO: MediaRecorder vs CCapture code here - can be refactored to be a "RecordingProvider"
  if(payload.isMediaRecorder){
    changeBorderLayerOpacity(ad);
    // now all the frames have been drawn
    ad.recorder.export().then(recorded => {
      var filename = `${payload.fileName}.${payload.extension}`;
      payload.videoBlobs.push({ blob: recorded, adId: ad.adId, name: filename, filename });
    })
  }
  else {
    var resolvePromise = null;
    var promise = new Promise((resolve, reject) => {
      resolvePromise = resolve
    })
    // payload.capturer.save(); // uncomment to export the base webm generated
    payload.builder.save({ payload, ad, resolvePromise });
    await promise;
  }
}

// Vuex actions
export const animationActions = {
  updateAnimations(context, payload) {
    context.commit('updateAnimations', payload);
    context.state.unsavedChanges += 1;
  },
  initializeAnimationFocusMode(context) {
    context.commit('initializeAnimationFocusMode');
    context.state.unsavedChanges += 1;
  },
  applyChangesToFocussedAnimations(context) {
    context.commit('applyChangesToFocussedAnimations');
    context.state.unsavedChanges += 1;
  },
  resetFocusModeAnimations(context) {
    context.commit('resetFocusModeAnimations');
    context.state.unsavedChanges += 1;
  },
  setShowTimeLine(context, payload) {
    context.commit('setShowTimeLine', payload);
  },
  async startAnimations(context, payload) {
    context.commit('setCanvasInteractionState', false);
    await startAnimations(context.state, {
      ...payload,
      isMediaRecorder: context.getters.roimaticConfiguration.isMediaRecorder
    });
  },
  updateSelectedPosition(context, payload) {
    context.commit('updateSelectedPosition', payload);
  },
  updateDragTimerIndicator(context, payload) {
    context.commit('updateDragTimerIndicator', payload);
  },
  updateIsTimelineDragging(context, payload) {
    context.commit('updateIsTimelineDragging', payload);
  },
  resetFabricObjectProps(context, payload) {
    context.commit('resetFabricObjectProps', payload);
    context.commit('resetAnimatedAds');
    context.commit('setCanvasInteractionState', true);
  },
  resetAnimatedAds(context)  {
    context.commit('resetAnimatedAds');
    context.commit('setCanvasInteractionState', true);
  },
  updateLoopCount(context, payload) {
    context.commit('updateLoopCount', payload);
  },
  resetVideoBlobs(context) {
    context.commit('resetVideoBlobs');
  },
  pauseHTMLMediaElements(context, payload) {
    context.commit('pauseHTMLMediaElements', payload);
  },
  updateTimelineLayerOrder(context, payload) {
    context.commit('updateTimelineLayerOrder', payload);
    context.state.unsavedChanges += 1;
  },
  resetAnimations(context) {
    context.commit('resetAnimations');
  }
}

export const changeAnimationBasedOnDelay = (animation, index) => {
  let childAnimationStartDelay = (index * animation.childAnimation.startDelay) / 1000;
  let childAnimationMiddleDelay = (index * animation.childAnimation.middleDelay) / 1000;
  let childAnimationEndDelay = (index * animation.childAnimation.endDelay) / 1000;

  animation.layerStartTime = animation.layerStartTime + childAnimationStartDelay;
  animation.layerEndTime = animation.layerEndTime + childAnimationEndDelay;

  animation.start.startTime = animation.start.startTime + childAnimationStartDelay;
  animation.start.endTime = animation.start.endTime + childAnimationStartDelay;
  animation.middle.startTime = animation.middle.startTime + childAnimationMiddleDelay;
  animation.middle.endTime = animation.middle.endTime + childAnimationMiddleDelay;
  animation.end.startTime = animation.end.startTime + childAnimationEndDelay;
  animation.end.endTime = animation.end.endTime + childAnimationEndDelay;
  return animation;
}

export const calculateMaxAdEndTime = (stateLayers, animations) => {
  var layerEndTimes = animations.map((animation) => {
    const stateLayer = stateLayers.find(l => l.id === animation.layerId);

    if (!stateLayer || !stateLayer.isMultiplyAnimation) {
      return animation.layerEndTime;
    }

    const childAnimationEndTime = animation.layerEndTime + (animation.childAnimation.count * (animation.childAnimation.endDelay / 1000));
    return Math.max(childAnimationEndTime, animation.layerEndTime);
  });
  return Math.max(
    ...layerEndTimes
  );
}

/** State object for startAnimations
 * @typedef {Object} AnimationState
 * @property {Array<any>} animations
 * @property {Array<any>} layers
 * @property {Array<any>} adsUnderAnimation
 * @property {Object} ffmpeg
*/
/**
 * AnimationPayload.timeline
 * @typedef {{play: Boolean, time: Number, count: Number, tween: Object}} timeline
*/
/** Payload object for startAnimations
 * @typedef {Object} AnimationPayload
 * @property {Array<any>} ads
 * @property {Array<any>} rafRequests
 * @property {Array<any>} tweenGroups
 * @property {*} downloadLoopCount
 * @property {Object} encoderOptions - {format: 'gif' / 'webm', downloadLoopCount: 1-Forever, framerate, palette, reserve_transparent}
 * @property {*} resetGroup
 * @property {*} reset
 * @property {timeline} timeline - For download: {play: true, time: 0}, For TimeLine {play,time,count,tween}
 * @property {Boolean} isMediaRecorder
 * @property {String} fileName
 * @property {*} builder - Instance of video builder.
*/
/**
 * Starts the animations
 * @param {AnimationState} state - the vuex state object
 * @param {AnimationPayload} payload - the payload used for animation
 */
export async function startAnimations(state, payload) {
  var ads = payload.ads;
  var rafRequests = payload.rafRequests || []; // Passed in if being invoked from TimeLine.vue
  var tweenGroups = payload.tweenGroups || []; // Passed in if being invoked from TimeLine.vue
  var downloadLoopCount = payload.downloadLoopCount;
  // If the download format is GIF, the animation loop should only run once. The GIF repeat is set in CCapture props.
  if(payload.encoderOptions && payload.encoderOptions.extension == 'gif') {
    downloadLoopCount = 1;
  }
  // contains tweens needed for reset.
  let resetGroup = payload.resetGroup || new TWEEN.Group(); // Passed in if being invoked from TimeLine.vue
  let reset = payload.reset || false; // To check whether timeline play button is pressed after pause/drag.
  var timeline = payload.timeline;
  var timelineTime = timeline.time > 0 ? timeline.time : 0;
  var sliderTween = timeline && timeline.tween; // Probably the tween which manages user sliding the timeline
  const mediaUrls = {
    audios: [],
    videos: []
  };
  // Incase of download we need to use the downloadLoopCount variable value for loopCount, in other cases this value will be null.
  var loopCount = downloadLoopCount ? downloadLoopCount :  payload.timeline.count;
  var audioCtx = getAudioContext();
  // create a stream from our AudioContext
  var dest = audioCtx.createMediaStreamDestination();
  var aStream = dest.stream;
  let isAudioPresent = false;
  var fileName = payload.fileName;
  var adVideos = [];
  let groupedVideos = [];
  const videoArrays = payload.videoArrays;
  var builder = payload.builder;
  state.adsUnderAnimation.splice(0, state.adsUnderAnimation.length, ...ads);
  await ads.forEachAsync(async (ad, index) => {
    removeChildFabricObjects(ad);
    ad.canvas.renderAll();
    // If reset is true we need to reset the fabricobjects before generating tweens.
    if (reset) {
      resetAds(state.ads, ad.adId, timeline.play);
    }
    let animations = getAnimationConfig(ad);
    payload.variant && payload.variant.layers.forEach((variantLayer) => {
      if (variantLayer.animations && !isEmpty(variantLayer.animations)) {
        let animationLayer = animations.find(a => a.layerId === variantLayer.id);
        deepAssign(animationLayer, variantLayer.animations);
      }
    });
    ad.maxAdEndTime = calculateMaxAdEndTime(state.layers, animations);
    var allLoopsDuration = ad.maxAdEndTime * 1000 * loopCount;
    var tweenGroup = { group: new TWEEN.Group(), tweenLayers: [], additionalGroups: [] };
    tweenGroups.push(tweenGroup);
    await ad.layers.forEachAsync(async layer => {
      var stateLayer = state.layers.find(l => l.id == layer.parentId);
      if (stateLayer.animationLayerType == animationLayerTypes.static && !stateLayer.isMultiplyAnimation) {
        return;
      }
      // tweens should be pushed to 'tweens' array in the order of there start.
      var tweenLayer = { id: layer.id, tweens: [] };
      tweenGroup.tweenLayers.push(tweenLayer);
      // Maintain a time variable, to keep track of current time, so that it can be used to calculate delay time between tweens.
      var time = 0;
      const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
      var fabricObject = layer.fabricObject;
      if (layer.type === layerTypes.text && computedLayer.data.filters.shadow.enabled) {
        fabricObject.objectCaching = false;
      }
      layer.fabricObjectBackUp = pick(layer.fabricObject, resetProperties);
      if (layer.fabricObject?.getObjects && layer.fabricObject.getObjects().length) {
        const buttonRect = layer.fabricObject.getObjects().find(obj => obj.type === 'rect');
        if (buttonRect) {
          layer.fabricObjectBackUp.innerFill = buttonRect.fill;
        }
      }
      if (layer.fabricObject.styles) layer.fabricObjectBackUp.styles = JSON.parse(JSON.stringify(layer.fabricObject.styles));
      let animation = animations.find(x => x.layerId == layer.parentId);
      if (stateLayer.isMultiplyAnimation && animation.childAnimation.count) {
        for (let i = 1; i <= animation.childAnimation.count; i++) {
          // we need to clone the tweenLayer but with the index id append to layerId
          let clonedTweenLayer = JSON.parse(JSON.stringify(tweenLayer));
          clonedTweenLayer.id = `${layer.id}_${i}`;
          tweenGroup.tweenLayers.push(clonedTweenLayer);
        }
      }
      if(layer.type == layerTypes.subtitle) {
        var subtitlesText;
        if(ad.localLayers && ad.localLayers.length > 0)
        {
          computedLocalLayer = JSON.parse(JSON.stringify(stateLayer));
          var localLayer = ad.localLayers.find(x => x.id = layer.parentId);
          deepAssign(computedLocalLayer, localLayer);
          subtitlesText = computedLocalLayer.data.subtitlesText;
        }
        else
          subtitlesText = stateLayer.data.subtitlesText;
        var filteredsubtitlesText = subtitlesText.filter(x => x.end <= ad.maxAdEndTime * 1000);
        filteredsubtitlesText.forEach((x, index) => {
          var startDelay = index == 0 ? x.start : x.start - filteredsubtitlesText[index - 1].end;
          var enablePeriod = x.end - x.start;
          tweenLayer.tweens.push(tweenGenerator.changeFabricObject(startDelay, fabricObject, tweenGroup.group, { opacity: animateConstants.maxOpacity, text: x.text }));
          tweenLayer.tweens.push(tweenGenerator.changeFabricObject(enablePeriod, fabricObject, tweenGroup.group, { opacity: animateConstants.minOpacity }));
        });
        if(filteredsubtitlesText.length > 0) time = filteredsubtitlesText[filteredsubtitlesText.length - 1].end;
      }
      else{
        if(computedLayer.data.hidden) isAudioPresent = false;
        else isAudioPresent = await generateMediaElements(state, layer, ad, timeline, animation, downloadLoopCount, ads, tweenLayer, tweenGroup, timelineTime, loopCount, adVideos, computedLayer);
        if (layer.type == layerTypes.text) genericUtilFunctions.textWrapHandler(fabricObject, ad.canvas);

        // mediaUrls are needed later to stitch audios to final output video.
        // need start and end times in case trimming is needed
        if (layer.type === layerTypes.audio && !computedLayer.data.hidden && downloadLoopCount) {
          let audioDuration = formatTimeToMilliseconds(computedLayer.data.props.duration);
          let audioUrl = {
            id: stateLayer.id,
            layerStartTime: animation[positions.start].startTime,
            layerEndTime: animation[positions.end].endTime,
            videoStartTime: computedLayer.data.props.startTime,
            videoEndTime: computedLayer.data.props.endTime,
            duration: audioDuration
          };
          if (stateLayer.data.props.useTts) {
            audioUrl.url = stateLayer.data.props.ttsConfiguration.data;
            audioUrl.useTts = true;
          }
          else {
            stateLayer.data.props.src = replaceWithCDN(stateLayer.data.props.src);
            audioUrl.url = stateLayer.data.props.src;
            audioUrl.useTts = false;
          }
          mediaUrls.audios.push(audioUrl);
        } else if (layer.type === layerTypes.video && !computedLayer.data.hidden && downloadLoopCount) {
          computedLayer.data.props.src = replaceWithCDN(computedLayer.data.props.src);
          mediaUrls.videos.push({
            id: stateLayer.id,
            layerStartTime: animation[positions.start].startTime,
            layerEndTime: animation[positions.end].endTime,
            videoStartTime: computedLayer.data.props.startTime,
            videoEndTime: computedLayer.data.props.endTime,
            url: computedLayer.data.props.src,
            isAudioPresent: false,
            loop: computedLayer.data.props.loop
          });
        }

        if (layer.type === layerTypes.image && computedLayer.data.multipleSources) {
          layer.fabricObjectBackUp.source = computedLayer.data.props.sources[0];
          animation.sources.forEach((animationSource, index) => {
            animationSource.layerStartTime = animation.layerStartTime;
            animationSource.layerEndTime = animation.layerEndTime;
            animationSource.layerId = animation.layerId;
            if (index > 0) {
              let styleObj = { top: layer.fabricObjectBackUp.top, left: layer.fabricObjectBackUp.left };
              tweenLayer.tweens.push(tweenGenerator.changeFabricObject(animationSource.start.startTime * 1000 - time, layer.fabricObject, tweenGroup.group, styleObj));
              time = animationSource.start.startTime * 1000;
            }
            time = generateTweens(animationSource, layer, ad, tweenLayer, tweenGroup, loopCount, time, timelineTime, index, computedLayer.data);
          });
          Object.assign(layer.fabricObject, layer.fabricObjectBackUp);
        }
        else {
          // make all positions type to none before starting static animation in multiply mode.
          if (stateLayer.animationLayerType == animationLayerTypes.static && stateLayer.isMultiplyAnimation)
          {
            resetPositionsToNone(animation);
          }
          time = generateTweens(animation, layer, ad, tweenLayer, tweenGroup, loopCount, time, timelineTime);
          if (stateLayer.isMultiplyAnimation && animation.childAnimation.count) {
            for (let i = 1; i <= animation.childAnimation.count; i++) {
              let clonedAnimation = JSON.parse(JSON.stringify(animation));
              clonedAnimation = changeAnimationBasedOnDelay(clonedAnimation, i);

              let clonedTweenLayer = tweenGroup.tweenLayers.find(x => x.id == `${layer.id}_${i}`);

              let clonedLayer = JSON.parse(JSON.stringify(layer));

              const clonedFabricObject = _.clone(layer.fabricObject);
              clonedLayer.fabricObject = clonedFabricObject;
              clonedLayer.fabricObject.top += (clonedFabricObject.height * clonedFabricObject.scaleY * i) + (i * animation.childAnimation.space);
              clonedLayer.fabricObject.left += i * animation.childAnimation.offset;
              if (animation.childAnimation.opacity) {
                clonedLayer.fabricObject.opacity += i * animation.childAnimation.opacity / 100;
              }
              clonedLayer.fabricObjectBackUp = pick(clonedLayer.fabricObject, resetProperties);

              clonedLayer.fabricObject.isChild = true;
              ad.canvas.add(clonedLayer.fabricObject);
              ad.canvas.renderAll();
              var clonedTime = 0;
              // hide it initially
              clonedTweenLayer.tweens.push(tweenGenerator.hideFabricObject(0, clonedLayer.fabricObject, tweenGroup.group));
              // generate tweens for each child animation
              clonedTime = generateTweens(clonedAnimation, clonedLayer, ad, clonedTweenLayer, tweenGroup, loopCount, 0, timelineTime);
              if(clonedAnimation.layerEndTime < ad.maxAdEndTime)
              {
                // hide layer if its endtime is less than a loop duration.
                clonedTweenLayer.tweens.push(tweenGenerator.changeFabricObject(clonedAnimation.layerEndTime * 1000 - clonedTime, clonedLayer.fabricObject, tweenGroup.group, { opacity: animateConstants.minOpacity }));
                clonedTime = clonedAnimation.layerEndTime * 1000;
              }
            }
          }
        }
        // hide layer if its endtime is less than a loop duration.
        if(animation.layerEndTime < ad.maxAdEndTime)
        {
          tweenLayer.tweens.push(tweenGenerator.changeFabricObject(animation.layerEndTime * 1000 - time, fabricObject, tweenGroup.group, { opacity: animateConstants.minOpacity }));
          time = animation.layerEndTime * 1000;
        }
      }

      // making opacity to min while resetting, to make sure fabric object doesn't appear before starting next loop.
      // 'text' property reset should be skipped, to keep textwrap changes.
      if(loopCount > 1) {
        var styleObj = Object.assign(pick(layer.fabricObjectBackUp, resetProperties.filter(x=>x != 'text')), { opacity: animateConstants.minOpacity });
        if (layer.fabricObjectBackUp.styles) styleObj.styles = JSON.parse(JSON.stringify(layer.fabricObjectBackUp.styles));
        tweenLayer.tweens.push(tweenGenerator.changeFabricObject(ad.maxAdEndTime * 1000 - time, fabricObject, tweenGroup.group, styleObj));
      }

      // hide layers whose starttime is not mintime before starting animation.
      // subtitles text default value ('sample Text') should not be shown on start of animation
      if(layer.type == layerTypes.subtitle || animation.layerStartTime > minTime)
      {
        Object.assign(fabricObject, { opacity: animateConstants.minOpacity });
        ad.canvas.renderAll();
      }
    });
    tweenGroup.tweenLayers.forEach(x => x.tweens.forEach((y, index) => {
      if(x.tweens.length != index + 1) {
        y.chain(x.tweens[index + 1])
      }
    }));
    if(loopCount > 1) tweenGroup.tweenLayers.forEach(x => {
      var l = x.tweens.length;
      if(l > 0) x.tweens[l - 1].chain(x.tweens[0]);
    });
    //In case of drag, update tween group once and render the canvas.
    if(!timeline.play) {
      await timelineDrag(tweenGroup, timelineTime, ad, tweenGroups, resetGroup, adVideos, groupedVideos, ads, index, state);
    }
    else{
      if(downloadLoopCount) {
        if(payload.isMediaRecorder){
          const canvas = document.getElementById(ad.adId);
          const FPS = 30;
          // initializes and starts media recorder.
          ad.recorder = new FrameByFrameCanvasRecorder(canvas, FPS, (isAudioPresent ? aStream : null), allLoopsDuration);
          // Triggered when it detects change in canvas.
          ad.recorder.recorder.onstart = function(){
            let frame = 0;
            const recorderFunc = () => {
              ad.recorder.recordFrame().then(async () => {
                if (frame === 0) {
                  await callAnimationFunctions(sliderTween, tweenGroup, timelineTime, ad, { loopCount: downloadLoopCount, isMediaRecorder: payload.isMediaRecorder }, state, loopCount, rafRequests, resetGroup, allLoopsDuration, fileName, animations);
                }
                frame++;
                if (frame < FPS * allLoopsDuration) {
                  recorderFunc();
                }
              })
            }
            recorderFunc();
          }
          // Change canvas to trigger onstart callback.
          changeBorderLayerOpacity(ad);
        }
        else {
          var renderOptions = payload.encoderOptions ?
            JSON.parse(JSON.stringify(payload.encoderOptions)) : {
              format: 'webm',
              framerate: defaultFps,
              downloadLoopCount,
              extension: 'mp4'
            };
          await builder.start({
            state,
            ad,
            videoArrays,
            mediaUrls,
            framerate: renderOptions.framerate
          });
          const renderFunc = async () => {
            await callAnimationFunctions(sliderTween, tweenGroup, timelineTime, ad, { loopCount: downloadLoopCount, isMediaRecorder: payload.isMediaRecorder, format: renderOptions.format }, state, loopCount, rafRequests, resetGroup, allLoopsDuration, fileName, animations, mediaUrls, videoArrays, builder, renderOptions);
          }
          await renderFunc();
        }
      }
      else {
        if (!reset) {
          state.animationsBackup = JSON.parse(JSON.stringify(state.animations)); //Copying Global Animations To Backup Animations, Only When Animation Starts But Not On Resume.
        }
        state.animations = animations;
        await callAnimationFunctions(sliderTween, tweenGroup, timelineTime, ad, { loopCount: downloadLoopCount }, state, loopCount, rafRequests, resetGroup, allLoopsDuration, fileName);
        state.animations = JSON.parse(JSON.stringify(state.animationsBackup));
      }
    }
  });
}

export const getAnimationConfig =  (ad) => {
  const globalAnimations = $store.getters.animations;
  let animations;
  if (!ad.localAnimations || ad.localAnimations.length == 0) {
    return globalAnimations;
  }
  animations = JSON.parse(JSON.stringify(ad.localAnimations));
  animations.forEach(layer => {
    const currentGlobalAnimationLayer = globalAnimations.find((x) => x.layerId == layer.layerId);
    const currentGlobalLayer = $store.getters.layers.find((x) => x.id == layer.layerId);
    if (currentGlobalLayer.type === layerTypes.image && currentGlobalLayer.data?.multipleSources) {
      for (let i = 0; i < layer.sources.length; i++){
        if (layer.sources[i].start.type === typeNames.none) {
          layer.sources[i].start.type = currentGlobalAnimationLayer.sources[i].start.type;
        }
        if (layer.sources[i].middle.type === typeNames.none) {
          layer.sources[i].middle.type = currentGlobalAnimationLayer.sources[i].middle.type;
        }
        if (layer.sources[i].end.type === typeNames.none) {
          layer.sources[i].end.type = currentGlobalAnimationLayer.sources[i].end.type;
        }
      }
    }
    else {
      if (layer.start.type === typeNames.none) {
        layer.start = JSON.parse(JSON.stringify(currentGlobalAnimationLayer.start));
      }
      if (layer.middle.type === typeNames.none) {
        layer.middle = JSON.parse(JSON.stringify(currentGlobalAnimationLayer.middle));
      }
      if (layer.end.type === typeNames.none) {
        layer.end = JSON.parse(JSON.stringify(currentGlobalAnimationLayer.end));
      }
    }
  })
  return animations;
}
const resetAds = (ads, adId, isPlay) => {
  ads.forEach(ad => {
    ad.layers.forEach(layer => {
      if (!layer.fabricObjectBackUp) {
        return;
      }
      let styleObj = pick(layer.fabricObjectBackUp, resetProperties);
      if (layer.fabricObjectBackUp.styles) styleObj.styles = JSON.parse(JSON.stringify(layer.fabricObjectBackUp.styles));
      Object.assign(layer.fabricObject, styleObj);
      const fabricObjects = layer.fabricObject.getObjects?.();
      if (fabricObjects && fabricObjects.length) {
        resetButtonFill(layer);
      }
      if (ad.adId != adId && isPlay) {  // reset other ads which are not being played
        ad.canvas.renderAll();
      }
    });
  });
}

export const generateTweens = (animation, layer, ad, tweenLayer, tweenGroup, loopCount, time, timelineTime, index, data) => {
  let fabricObject = layer.fabricObject;
  if (index >= 0) {
    let source = data.props.sources[index];
    calculateScaleForFitOption(data, source.width, source.height);
    let { scaleX, scaleY } = data.styles;
    Object.assign(layer.fabricObject, { scaleX, scaleY });
  }
  // this object is used to get updated properties after every transition.
  layer.updatedFB = pick(layer.fabricObject, [...resetProperties]);
  let updatedFB = layer.updatedFB;
  updatedFB.centerPoint = fabricObject.getCenterPoint();
  updatedFB.scaledHeight = fabricObject.getScaledHeight();
  updatedFB.scaledWidth = fabricObject.getScaledWidth();
  // if start animation is applied, play start animation at given startime.
  if (animation.start.type != typeNames.none)
  {
    let animationStart = animation[positions.start];
    var startDuration = (animationStart.endTime - animationStart.startTime) * 1000;
    let fabricName = animationStart.easing;
    let easing = easingFunctions.find(x => x.fabricName == (fabricName ? fabricName : animateConstants.defaultEase));
    const startAnimationLevel = animation.start.animationLevel ? animation.start.animationLevel : levels.layer;
    let animationStartConfigs = JSON.parse(JSON.stringify(animationStart.configs));
    if(layer.type == layerTypes.shape) {
      animationStartConfigs.scaleStart !== currentAnimationConfigValue && (animationStartConfigs.scaleStart = (animationStartConfigs.scaleStart * 0.25).toString());
      animationStartConfigs.scaleEnd !== currentAnimationConfigValue && (animationStartConfigs.scaleEnd = (animationStartConfigs.scaleEnd * 0.25).toString());
    }
    if (startAnimationLevel == levels.layer)
    {
      tweenLayer.tweens.push(
        ...startAndEndTransitions.find(x => x.name == animation.start.type).getTweens(
          layer.fabricObject,
          tweenGroup.group,
          updatedFB,
          (animationStart.endTime - animationStart.startTime) * 1000,
          animationStart.startTime * 1000 - time,
          easing,
          ad.canvas,
          animationStartConfigs,
          timelineTime,
          layer.type
        )
      );
    }
    else {
      if (isFillTypeAnimationForGradient(animation.start.type, layer.fabricObject)) {
        startDuration = 0;
      } else {
        // AY TODO: Need to get rid of this string interpolation for function name - so painful to navigate!
        let tweens = genericUtilFunctions[`${animation.start.animationLevel}UtilAnimationHandler`](
          layer.fabricObject,
          animation.start.startTime * 1000 - time,
          startDuration,
          ad.canvas,
          animation,
          positions.start,
          tweenGroup.group,
          easing,
          tweenGroup.additionalGroups,
          animation[positions.start].startTime * 1000,
          loopCount,
          ad.maxAdEndTime * 1000,
          animationStartConfigs
        );
        if(tweens.length > 0)   tweenLayer.tweens.push(...tweens);
      }
    }
    time =  animation[positions.start].startTime * 1000 + startDuration;
  }
  // When there is no animation applied at position start, show the layer at its starttime.
  else {
    let delay = time <= 0 ? animation.layerStartTime * 1000 : 0;
    tweenLayer.tweens.push(tweenGenerator.changeFabricObject(delay, fabricObject, tweenGroup.group, { opacity: layer.fabricObjectBackUp.opacity }));
    time = animation[positions.start].startTime * 1000;
  }

  // if middle animation is applied, play middle animation at given startime.
  const middleAnimationLevel = animation.middle.animationLevel ? animation.middle.animationLevel : levels.layer;
  if(animation.middle.type != typeNames.none) {
    let middleAnimation = animation[positions.middle]
    var middleDuration = (middleAnimation.endTime - middleAnimation.startTime) * 1000;
    let fabricName = middleAnimation.easing;
    let easing = easingFunctions.find(x => x.fabricName == fabricName ? fabricName : animateConstants.defaultEase);
    const fillTypeMiddleAnimation =
      middleAnimationLevel !== levels.layer &&
      isFillTypeAnimationForGradient(animation.middle.type, layer.fabricObject);
    let middleAnimationConfigs = JSON.parse(JSON.stringify(middleAnimation.configs));
    if(layer.type == layerTypes.shape) {
      middleAnimationConfigs.scaleStart !== currentAnimationConfigValue && (middleAnimationConfigs.scaleStart = (middleAnimationConfigs.scaleStart * 0.25).toString());
      middleAnimationConfigs.scaleEnd !== currentAnimationConfigValue && (middleAnimationConfigs.scaleEnd = (middleAnimationConfigs.scaleEnd * 0.25).toString());
    }
    if (middleAnimationLevel == levels.layer) {
      tweenLayer.tweens.push(...middleTransitions[animation.middle.type](
        fabricObject,
        tweenGroup.group,
        updatedFB,
        middleDuration,
        middleAnimation.startTime * 1000 - time,
        easing,
        ad.canvas,
        middleAnimationConfigs
      )
      );
    }
    else if (middleAnimationLevel != levels.layer && !fillTypeMiddleAnimation) {
      let middleAnimationFunctions = genericUtilFunctions[`${middleAnimationLevel}MiddleAnimationsHandler`](layer.fabricObject,
        animation.middle.startTime * 1000 - time, middleDuration, ad.canvas, animation, positions.middle, tweenGroup.group, easing, middleAnimationConfigs);
      tweenLayer.tweens =  tweenLayer.tweens.concat(middleAnimationFunctions);
    }
    if (!fillTypeMiddleAnimation) {
      time = middleAnimation.startTime * 1000 + middleDuration;
    }
  }

  // if end animation is applied, play end animation at given startime.
  const endAnimationLevel = animation.end.animationLevel ? animation.end.animationLevel : levels.layer;
  if (animation.end.type != typeNames.none){
    let animationEnd = animation[positions.end];
    var endDuration = (animationEnd.endTime - animationEnd.startTime) * 1000;
    let fabricName = animationEnd.easing;
    let easing = easingFunctions.find(x => x.fabricName == fabricName ? fabricName : animateConstants.defaultEase);
    const fillTypeEndAnimation =
      endAnimationLevel !== levels.layer &&
      isFillTypeAnimationForGradient(animation.end.type, layer.fabricObject);
    let endAnimationConfigs = JSON.parse(JSON.stringify(animationEnd.configs));
    if(layer.type == layerTypes.shape) {
      endAnimationConfigs.scaleStart !== currentAnimationConfigValue && (endAnimationConfigs.scaleStart = (endAnimationConfigs.scaleStart * 0.25).toString());
      endAnimationConfigs.scaleEnd !== currentAnimationConfigValue && (endAnimationConfigs.scaleEnd = (endAnimationConfigs.scaleEnd * 0.25).toString());
    }
    if (endAnimationLevel == levels.layer) {
      tweenLayer.tweens.push(
        ...startAndEndTransitions.find(x=>x.name == animation.end.type).getTweens(
          layer.fabricObject,
          tweenGroup.group,
          updatedFB,
          endDuration,
          animationEnd.startTime * 1000 - time,
          easing,
          ad.canvas,
          endAnimationConfigs,
          timelineTime,
          layer.type
        )
      );
    } else if (animation.end.animationLevel != levels.layer && !fillTypeEndAnimation) {
      // AY TODO: Need to get rid of this string interpolation for function name - so painful to navigate!
      let endAnimationFunctions = genericUtilFunctions[`${endAnimationLevel}UtilAnimationHandler`](layer.fabricObject,
        animation.end.startTime * 1000 - time, endDuration, ad.canvas, animation, positions.end, tweenGroup.group, easing,
        tweenGroup.additionalGroups, animation[positions.end].startTime * 1000, loopCount, ad.maxAdEndTime * 1000, endAnimationConfigs);
      tweenLayer.tweens.push(...endAnimationFunctions);
    }
    if (!fillTypeEndAnimation) {
      time = animation[positions.end].startTime * 1000 + endDuration;
    }
  }
  return time;
}

export const splitVideoToImageUrls = async (ffmpeg, payload) => {
  // In case of ad download, video will be split into frames instead of generating html element.
  // While rendering the frames, the corresponding video frame according to current time will be captured and
  // fabric.Image will be updated.
  const { videoArrays, mediaUrls, framerate, state } = payload;
  const videoLayers = payload.ad.layers.filter(l => l.type == layerTypes.video);
  await videoLayers.forEachAsync(async videoLayer => {
    const layer = state.layers.find(l => l.id == videoLayer.parentId);
    const computedLayer = getComputedLayer(state.layers, payload.ad, videoLayer.parentId);
    computedLayer.data.props.src = replaceWithCDN(computedLayer.data.props.src);
    const videoUrl = mediaUrls.videos.find(v => v.id === layer.id);
    if (!videoUrl) {
      return;
    }
    const videoArray = videoArrays.find(v => v.id === videoLayer.parentId && v.src === computedLayer.data.props.src);
    if (videoArray && videoArray.fileUrls && videoArray.fileUrls.length > 0) {
      videoUrl.isAudioPresent = computedLayer.data.styles.muted ? !computedLayer.data.styles.muted : videoArray.isAudioPresent;
      videoUrl.duration = videoArray.duration;
      videoUrl.audioBitrate = videoArray.audioBitrate;
      return;
    }
    await fetchAndWriteFile(ffmpeg, computedLayer.data.props.src);
    const videoMetaData = await getVideoData(ffmpeg, computedLayer.data.props.src);
    await splitFileToImages(ffmpeg, computedLayer, framerate, payload, videoMetaData);
    const videoDuration = formatTimeToMilliseconds(computedLayer.data.props.duration);
    // If the layer is muted then keep muted, else check the meta data for any audio stream
    videoUrl.isAudioPresent = computedLayer.data.styles.muted ? !computedLayer.data.styles.muted : videoMetaData.isAudioPresent;
    videoUrl.duration = videoDuration;
    videoUrl.audioBitrate = videoMetaData.audioBitrate;
    deleteFile(ffmpeg, 'video.mp4');
    const fileUrls = [];
    const totalFrames = Math.floor(videoDuration / 1000 * defaultFps);
    for (let i = 1; i <= totalFrames; i += 1) {
      try {
        let fileName = `out-${(i).toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false })}.png`;
        await prepareVideoFileUrls(ffmpeg, fileUrls, fileName, payload, computedLayer.id);
        deleteFile(ffmpeg, fileName);
      }
      catch (err) {
        break;
      }
    }
    videoArrays.push({
      id: layer.id,
      src: computedLayer.data.props.src,
      fileUrls,
      isAudioPresent: videoMetaData.isAudioPresent,
      duration: videoUrl.duration,
      audioBitrate: videoUrl.audioBitrate
    });
  });
};

// Gradient color is not supported for letter/word/line level animations.
// Skipping the animation of layer if true.
const isFillTypeAnimationForGradient = (animationType, fabricObject) => {
  if (!animationType || !fabricObject) {
    return false;
  }

  const isFillType = animationConfig.getAnimationPropList(animationType).includes(stylesProperties.fill);
  const isGradient = typeof fabricObject.fill === 'object' && fabricObject.fill !== 'null' && fabricObject.fill.colorStops;
  if (isFillType && isGradient) {
    return true;
  }

  return false;
};

// Vuex mutations
export const animationMutations = {
  updateAnimations(state, data) {
    var animation = state.animations.find(a => a.layerId == data.layerId);
    let animationBackup = null;
    if (state.animationsBackup && state.animationsBackup.length) {
      animationBackup = state.animationsBackup.find(a => a.layerId == animation.layerId);
    }
    updateLayerAnimation(animation, animationBackup, data);
    if (state.isAnimationsInFocusMode) {
      animation.isDirty = true;
    }
    state.ads.forEach(ad => {
      if (ad.localAnimations && ad.localAnimations.length > 0) {
        let localAnimationUpdate = ad.localAnimations.find(localAnimation => localAnimation.layerId == data.layerId);
        if (localAnimationUpdate) {
          if (data.update == animationUpdateTypes.ANIMATION_POSITION) {
            localAnimationUpdate[data.position].startTime = data.properties.startTime ?? localAnimationUpdate[data.position].startTime;
            localAnimationUpdate[data.position].endTime = data.properties.endTime ?? localAnimationUpdate[data.position].endTime;
          } else if (data.update == animationUpdateTypes.LAYER) {
            localAnimationUpdate.layerStartTime = data.properties.layerStartTime ?? localAnimationUpdate.layerStartTime;
            localAnimationUpdate.layerEndTime = data.properties.layerEndTime ?? localAnimationUpdate.layerEndTime;
          }
          if (localAnimationUpdate.isDirty) {
            // Check local animations if dirty return
            return;
          } else if (!state.isAnimationsInFocusMode) {
            //else update local layer with global layer
            updateLayerAnimation(localAnimationUpdate, animationBackup, data);
          }
        }
      }
    });
    if (data.update == animationUpdateTypes.LAYER) {
      updateStaticLayerAnimations(state.animations, state.layers);
    }
    // Update animations enabled flag in state
    state.areAnimationsApplied = timelineHelper.areAnimationsApplied(state.animations, state.ads);
  },
  initializeAnimationFocusMode(state) {
    let adOb;
    if (state.visibleAdIds && state.visibleAdIds.length) {
      adOb = state.ads.find(ad => ad.adId == state.visibleAdIds[0]);
    } else if (state.isInFocusMode) {
      adOb = state.focussedAds[0];
    }
    if (adOb && !adOb.localAnimations || adOb.localAnimations.length == 0) {
      adOb.localAnimations = JSON.parse(JSON.stringify(state.animations));
      adOb.localAnimations.forEach(x => { x.isDirty = false; })
    }
    else {
      state.localAnimationsBackup = JSON.parse(JSON.stringify(adOb.localAnimations));
    }
    state.animationsBackup = JSON.parse(JSON.stringify(state.animations)); //Copying Global Animations To Backup Animations
    state.animations = adOb.localAnimations;
    state.isAnimationsInFocusMode = true;
  },
  applyChangesToFocussedAnimations(state) {
    let adOb;
    if (state.visibleAdIds && state.visibleAdIds.length) {
      adOb = state.ads.find(ad => ad.adId == state.visibleAdIds[0]);
    } else if (state.isInFocusMode) {
      adOb = state.focussedAds[0];
    }
    if (adOb && adOb.localAnimations.length) {
      adOb.isAnimationsDirty = true;
    }
    state.animations = JSON.parse(JSON.stringify(state.animationsBackup));
    state.isAnimationsInFocusMode = false;
  },
  resetFocusModeAnimations(state) {
    state.animations = JSON.parse(JSON.stringify(state.animationsBackup));
    let adOb;
    if (state.visibleAdIds && state.visibleAdIds.length) {
      adOb = state.ads.find(ad => ad.adId == state.visibleAdIds[0]);
    } else if (state.isInFocusMode) {
      adOb = state.focussedAds[0];
    }
    adOb.localAnimations = [];
    if (adOb) {
      adOb.localAnimations = [];
      adOb.isAnimationsDirty = false;
    }
  },
  setShowTimeLine(state, show) {
    // AY TODO: This method is about UI rendering.
    state.showTimeLine = show;
    //When timeline is hidden, set animation component to hidden.
    if (state.showTimeLine == false)
      state.selectedPosition = { position: '', layerId: '' };
  },
  // updates layer id, position details
  // depending on these values animation component content changes.
  updateSelectedPosition(state, selectedPosition) {
    state.selectedPosition = selectedPosition;
  },
  updateDragTimerIndicator(state, payload) {
    state.dragTimerIndicatorInfo = payload;
  },
  updateIsTimelineDragging(state, payload) {
    state.isTimelineDragging = payload;
  },
  resetFabricObjectProps(state, payload) {
    var ads = payload.index ? payload.ads.slice(0, payload.index) : payload.ads;
    ads.forEach(async ad => {
      // skip unplayed ads; if ad is played, it ad.layer will have fabricObjectBackUp
      var adHasNotBeenPlayed = ad.layers.filter(x => x.fabricObjectBackUp).length == 0;
      if(adHasNotBeenPlayed) {
        return;
      }
      await ad.layers.forEachAsync(async layer => {
        if(!layer.fabricObjectBackUp) {
          return;
        }
        const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
        if (layer.type == layerTypes.text && computedLayer.data.filters.shadow.enabled) {
          layer.fabricObject.objectCaching = true;
        }
        let styleObj = pick(layer.fabricObjectBackUp, resetProperties);
        if(layer.fabricObjectBackUp.styles)
          styleObj.styles = JSON.parse(JSON.stringify(layer.fabricObjectBackUp.styles));
        else if(styleObj.styles)
          styleObj.styles = null;
        Object.assign(layer.fabricObject, styleObj);
        const fabricObjects = layer.fabricObject.getObjects?.();
        // internally fabricObjects [ rect, text ] will be present only for button layer, we need to reset the rect fill color.
        if (fabricObjects && fabricObjects.length) {
          resetButtonFill(layer);
        }
        if (layer.fabricObjectBackUp.hasOwnProperty('source')) {
          const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
          let source = layer.fabricObjectBackUp.source;
          await updateSourceOnCanvas({ ad, layerToUpdate: layer, data: computedLayer.data, source })
        }
      });
      ad.canvas.renderAll();
    });
  },
  resetAnimatedAds(state) {
    // removes all entries in state.adsUnderAnimation array
    state.adsUnderAnimation.splice(0, state.adsUnderAnimation.length);
    state.ads.forEach(ad => {
      removeChildFabricObjects(ad);
      ad.canvas.renderAll();
    });
  },
  updateLoopCount(state, loopCount) {
    state.loopCount = loopCount;
  },
  resetVideoBlobs(state) {
    state.videoBlobs = [];
  },
  pauseHTMLMediaElements(state, payload) {
    // AY TODO: This method depends on browser, need to abstract it out.
    var mediaLayerNames = [layerTypes.video, layerTypes.audio];
    // if global layers doesnt contain media elements, then return without checking each ad layers.
    if(state.layers.findIndex(x => mediaLayerNames.includes(x.type)) < 0) return;
    payload.ads.forEach(ad => {
      ad.layers.forEach(async (layer) => {
        if (layer.type !== layerTypes.audio && layer.type !== layerTypes.video) {
          return;
        }

        const mediaElement = layer.fabricObject.getElement();
        if(mediaElement.src) {
          mediaElement.pause();
          // in case of animation stop current time should be resetted.
          if(payload.reset) {
            mediaElement.currentTime = 0;
            await new Promise((resolve, reject)=>{
              mediaElement.addEventListener('seeked', (event) => {resolve()})
            })
            ad.canvas.renderAll();
          }
        }
      });
    });
  },
  updateTimelineLayerOrder(state, timelineLayerOrder) {
    state.timelineLayerOrder = [...timelineLayerOrder];
  },
  resetAnimations(state) {
    if (state.animationsBackup.length > 0) {
      state.animations = state.animationsBackup;
    }
  }
}

//Change image fabric object in animations
export const updateSources = async (state, ad, currentTime) => {
  const imageLayers = ad.layers.filter(l => l.type == layerTypes.image);
  await imageLayers.forEachAsync(async layer => {
    const animation = state.animations.find(a => a.layerId === layer.parentId);
    const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
    const multipleSources = computedLayer.data.multipleSources;
    if(multipleSources) {
      //Change imageSrc before starting animations
      const animationSources = animation.sources;
      for (let index = 0; index < animationSources.length; index++) {
        const source = animationSources[index];
        const endTime = source.end.endTime * 1000;
        const startTime = source.start.startTime * 1000;
        if (index == 0 && currentTime < startTime) {
          Object.assign(layer.fabricObject, { opacity: animateConstants.minOpacity });
          ad.canvas.renderAll();
        }
        else if (index >= 0 && index < animationSources.length - 1 && currentTime > endTime) {
          let nextSourceStartTime = animationSources[index + 1].start.startTime * 1000;
          if (currentTime < nextSourceStartTime) {
            Object.assign(layer.fabricObject, { opacity: animateConstants.minOpacity });
            ad.canvas.renderAll();
          }
        }
        else if (currentTime < startTime || currentTime > endTime) continue;
        else if (currentTime >= startTime && currentTime <= endTime) {
          let sourceToUpdate = computedLayer.data.props.sources[index];
          if (layer.fabricObject.getSrc() != sourceToUpdate.imageUrl) {
            await updateSourceOnCanvas({ ad, layerToUpdate: layer, data: computedLayer.data, source: sourceToUpdate });
            if (layer.fabricObjectBackUp) {
              Object.assign(layer.fabricObjectBackUp, layer.fabricObject);
            }
          }
        }
      }
    }
  });
}

const resetButtonFill = (layer) => {
  const buttonRect = layer.fabricObject.getObjects().find(obj => obj.type === 'rect');
  if (buttonRect && layer.fabricObjectBackUp.innerFill) {
    buttonRect.set({ fill: layer.fabricObjectBackUp.innerFill });
    layer.fabricObjectBackUp.innerFill = null;
  }
  if (buttonRect && buttonRect.originalFill) {
    buttonRect.set({ fill: buttonRect.originalFill });
    buttonRect.originalFill = null;
  }
}

//This method shift sources if there is a gap in animations for multiple sources layer
export const moveSources = (animation, index) => {
  //If End time of end position modified
  if (index < animation.sources.length - 1) {
    const sourceEndTime = animation.sources[index][positions.end].endTime;
    const nextSourceStartTime = animation.sources[index + 1][positions.start].startTime;
    if (nextSourceStartTime != sourceEndTime) {
      let diff = parseFloat((sourceEndTime - nextSourceStartTime).toFixed(1));
      let moveDirection = direction.forward;
      if (diff < 0) {
        if (!animation.isLinked) {
          return;
        }
        moveDirection = direction.backward;
        diff *= -1;
      }
      if (animation.sources[animation.sources.length - 1][positions.end].endTime <= maxTime) {
        for (let i = index + 1; i < animation.sources.length; i++){
          moveAllPositions(diff, moveDirection, animation, i);
        }
      }
      updateAnimationSources(animation);
    }
  }
  //If Start time of start position modified
  if (index > 0) {
    const sourceStartTime = animation.sources[index][positions.start].startTime;
    const prevSourceEndTime = animation.sources[index - 1][positions.end].endTime;
    if (prevSourceEndTime != sourceStartTime) {
      let diff = parseFloat((sourceStartTime - prevSourceEndTime).toFixed(1));
      if (diff < 0) {
        if (animation.sources[0][positions.start].startTime + diff >= minTime) {
          for (let i = 0; i <= index - 1; i++){
            moveAllPositions(diff * -1, direction.backward, animation, i)
          }
        }
      }
      else {
        if (animation.isLinked) {
          for (let i = index ; i < animation.sources.length; i++){
            moveAllPositions(diff, direction.backward, animation, i);
          }
        }
      }
      updateAnimationSources(animation);
    }
  }
}

export const updateAnimationSources = (animation) => {
  $store.dispatch('updateAnimations', {
    layerId: animation.layerId,
    update: animationUpdateTypes.LAYER,
    properties: {
      layerStartTime: animation.sources[0][positions.start].startTime,
      layerEndTime: animation.sources[animation.sources.length - 1][positions.end].endTime,
      sources: animation.sources
    }
  });
};

export const moveAllPositions = (moveLength, direction, animation, sourceIndex) => {
  // increases/decreases starttime & endtime of all positions depending on direction given
  Object.values(positions).forEach(positionName => {
    movePosition(positionName, direction, moveLength, animation, sourceIndex);
  });
}

// increases/decreases start/end timings of positions depending on given direction
export const movePosition = (positionName, moveDirection, moveLength, animation, sourceIndex, timing) => {
  let animationSource = sourceIndex >= 0 ? animation.sources[sourceIndex] : animation;
  if (moveLength == 0) return;
  if (moveDirection == direction.backward) moveLength *= -1;
  var properties;
  if (timing == timings.startTime)
    properties = {
      startTime:
        (
          animationSource[positionName].startTime * 1 +
          moveLength * 1
        ).toFixed(10) * 1
    };
  else if (timing == timings.endTime) {
    properties = {
      endTime:
        (animationSource[positionName].endTime * 1 + moveLength * 1).toFixed(
          10
        ) * 1
    };
  } else {
    properties = {
      startTime:
        (
          animationSource[positionName].startTime * 1 +
          moveLength * 1
        ).toFixed(10) * 1,
      endTime:
        (animationSource[positionName].endTime * 1 + moveLength * 1).toFixed(
          10
        ) * 1
    };
  }
  $store.dispatch('updateAnimations', {
    layerId: animation.layerId,
    update: sourceIndex == -1 ? animationUpdateTypes.ANIMATION_POSITION : animationUpdateTypes.ANIMATION_SOURCE_POSITION,
    sourceIndex: sourceIndex,
    position: positionName,
    properties
  });
}

// Remove cloned fabric objects related to multiply animation.
export const removeChildFabricObjects = (ad) => {
  const allFabricObjects = ad.canvas.getObjects();
  allFabricObjects.forEach(obj => {
    if (obj.isChild) {
      ad.canvas.remove(obj);
    }
  });
}

export const resetPositionsToNone = (animation) => {
  animation.start.type = 'None';
  animation.middle.type = 'None';
  animation.end.type = 'None';
}
