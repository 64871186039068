import { getLayoutPosition } from '../helpers/gridLayoutHelper';
import { calculateScaleForFitOption, updateImageCropAndPosition } from '../helpers/objectSizingHelper';
import { positions } from '../../externalTools/constants/animation';

//This is to change source on canvas during animations or when the image is clicked from layer panel
export const updateSourceOnCanvas = async (payload) => {
  var { ad, layerToUpdate, data, source } = payload;
  let fabricObject = layerToUpdate.fabricObject;
  if (!fabricObject) {
    return;
  }
  let imageSrc = source.imageUrl
  let { styles } = data;
  calculateScaleForFitOption(data, source.width, source.height);
  let adLayoutPosition = data.adLayoutPositions.find(layoutPosition => layoutPosition.layoutType == ad.layoutType);
  let layoutPosition = getLayoutPosition(
    { width: ad.width, height: ad.height },
    { width: source.width * styles.scaleX, height: source.height * styles.scaleY },
    adLayoutPosition.positionIndex,
    adLayoutPosition.positionOffset,
    !styles.hasOriginChanged
  );
  await fabricObject.setSrc(imageSrc, { crossOrigin: 'anonymous' });
  fabricObject.set({
    ...styles,
    dirty: true,
    left: layoutPosition.left,
    top: layoutPosition.top,
    visible: !data.hidden
  });
  updateImageCropAndPosition(fabricObject, data, adLayoutPosition, styles, ad, source);
  ad.canvas.renderAll();
}

export const getImageDimensions = (data) => {
  let width, height;
  if (!data.multipleSources || data.props.sources.length == 0) {
    width = data.props.width;
    height = data.props.height;
  }
  else {
    width = data.props.sources[0].width;
    height = data.props.sources[0].height;
  }
  return { width, height };
}

export const updateMultipleImagesAnimationSource = (defaultAnimation, animationSources, index = null) => {
  if (index != null) {
    let animationSource = animationSources[index];
    let animationSourcetime = animationSource[positions.end].endTime - animationSource[positions.start].startTime;
    animationSources.splice(index, 1);
    //Shift all other animation sources after deleted source to the front.
    for (let i = index; i < animationSources.length; i++){
      [positions.start, positions.middle, positions.end].forEach((pos) => {
        animationSources[i][pos].startTime -= animationSourcetime;
        animationSources[i][pos].endTime -= animationSourcetime;
      })
    }
  }
  else {
    let lastAnimationSource = animationSources[animationSources.length - 1];
    let animationSource = {};
    let positionsArray = [positions.start, positions.middle, positions.end];
    let startTime = lastAnimationSource ? lastAnimationSource[positions.end].endTime : 0;
    let endTime = startTime + (defaultAnimation.start.endTime - defaultAnimation.start.startTime);
    positionsArray.forEach((position, index) => {
      if (index) {
        startTime = endTime + defaultAnimation[position].startTime - defaultAnimation[positionsArray[index - 1]].endTime;
        endTime = startTime + defaultAnimation[position].endTime - defaultAnimation[position].startTime;
      }
      animationSource[position] = {
        startTime,
        endTime,
        type: defaultAnimation[position].type
      }
    });
    animationSource.isDirty = false;
    animationSources.push(animationSource);
  }
};
