import api from 'Api';

export class AppService {
  // This contains methods that handle app level requests
  async getUserData(emailAddress) {
    var response = await api.get('/users', {
      params: {
        email: emailAddress
      }
    });
    return response.data.data;
  }

  async getBusinessProfile(name, referralCode, storeDomain, currencyId = 17, timeZoneId = 142) {
    var response = await api.post('/businessProfiles', {
      name,
      currencyId,
      timeZoneId,
      IsRoimatic: true,
      referralCode,
      storeDomain
    });
    return response.data;
  }

  async assignUserAccess(userId, businessProfileId) {
    await api.post(`/users/${userId}/businessProfiles/${businessProfileId}`, {
      role: 4
    })
    return;
  }

  async assignBusinessProfilePortalAccess(businessProfileId, portalId) {
    await api.post(`/businessProfiles/${businessProfileId}/portals/${portalId}`)
    return;
  }

  async createProductCatalog(businessProfileId, name) {
    let response = await api.post(`/businessProfiles/${businessProfileId}/ProductCatalogs`, { name });
    return response.data;
  }

  async getProductCatalog(businessProfileId, productCatalogId) {
    let response = await api.get(`/businessProfiles/${businessProfileId}/ProductCatalogs/${productCatalogId}`);
    return response.data.data;
  }
  async createProductFeed(businessProfileId, name, productCatalogId, url, engine = 0) {
    let params = {
      name,
      productCatalogId,
      url
    };
    if(engine != 0){
      params.engine = engine;
    }
    let response = await api.post(`/businessProfiles/${businessProfileId}/ProductFeeds`, params);
    return response.data.data;
  }

  async createTask(businessProfileId, id, productCatalogId, engine, isDirectTask, isApiUploadRequired, productMappings, businessProfileSearchEngineId = 0) {
    let productMaps = productMappings === undefined || productMappings === null ? [] : productMappings;

    let params = {
      productCatalogId,
      engine,
      isDirectTask,
      isApiUploadRequired,
      productMaps
    };

    if(businessProfileSearchEngineId != 0){
      params.businessProfileSearchEngineId = businessProfileSearchEngineId;
    }
    await api.post(`/businessProfiles/${businessProfileId}/productFeeds/${id}/process`, params);
  }
  async getBusinessProfileById(id)
  {
    var response = await api.get(`/businessProfiles/${id}`);
    return response.data.data;
  }

  async getPortalBusinessProfiles(userId, portalId, isRoimatic = null) {
    let params = {
      userId: userId
    }
    if(isRoimatic){
      params.isRoimatic = isRoimatic
    }
    var response = await api.get(`/portals/${portalId}/users/${userId}/businessProfiles`, {
      params
    });
    return response.data.data;
  }

  async getUserBusinessProfiles(userId, isRoimatic = null) {
    let params = {
      isRoimatic: isRoimatic
    }
    var response = await api.get(`/users/${userId}/businessProfiles`, {
      params
    });
    return response.data.data;
  }

  async getCurrencies() {
    var response = await api.get('/currencies');
    let currencies = response.data.data;
    let currencyinfos = [];
    currencies.forEach((currency) => {
      let currencyinfo = {};
      currencyinfo.id = currency.id;
      currencyinfo.code = currency.code;
      currencyinfo.displayName = currency.displayName;
      currencyinfo.currencyOffset = currency.currencyOffset;
      currencyinfos.push(currencyinfo);
    });
    return currencyinfos;
  }
  async getTimeZones() {
    var response = await api.get('/timezones');
    return response.data.data;
  }

  async updateBusinessProfile(businessProfileId, businessProfile) {
    var payload = JSON.stringify(JSON.stringify({ 'data': businessProfile }));
    await api.patch(`/businessProfiles/${businessProfileId}`, payload, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  };

  async uploadBusinessProfileLogo(bpId, file) {
    var formData = new FormData();
    formData.append('logoFile', file);
    var response = await api.post(`/businessProfiles/${bpId}/logo`, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
    return response.data.data;
  }

  async getCurrencyInfo(currencyId, businessProfileId) {
    var response = await api.get(
      `/businessProfiles/${businessProfileId}/currencies/${currencyId}`
    );
    return response.data.data;
  }

  async getSearchCreatives(businessProfileId, theme) {
    let response = await api.get(
      `/businessProfiles/${businessProfileId}/roimatic/getSearchCreatives`, {
        params: {
          theme: theme
        } }
    );
    return response.data.data;
  }

  async getCountries() {
    var response = await api.get('/ip2locations/countries');
    return response.data.data;
  }

  async resetPassword(userId, currentPassword, newPassword) {
    await api.post(`/users/${userId}/resetPassword`, {
      currentPassword,
      newPassword
    });
  }

  async placeCallbackRequest(userId, number, businessProfileId, requestType = 0, comment = null) {
    await api.post('/users/callback', {
      businessProfileId: businessProfileId ? businessProfileId : null,
      phone: number,
      id: userId ? userId : null,
      portal: window.location.host,
      requestType: requestType,
      comment: comment
    });
  }

  async getUserInformation(userId) {
    return await api.get(`/users/${userId}`);
  }
  async updateUserInformation(userId, data) {
    await api.put(`/users/${userId}`, data);
  }

  async setupLocalBusiness(businessProfileId, businessName) {
    let postData = {
      businessName,
      outlet: 'roimatic.adbox.pro',
      category: 'local-business',
      flavour: 'local-business'
    };
    await api.post(`/businessProfiles/${businessProfileId}/roimatic/localBusiness`, postData)
  }

  async getAllLanguages() {
    let response = await api.get('dxlanguages');
    return response.data.data;
  }

  async getUserDivisions(userId, portalId) {
    let response = await api.get(`/users/${userId}/portals/${portalId}/divisions`);
    return response.data.data;
  }

  async editUserInformation(userId, data) {
    var payload = JSON.stringify(JSON.stringify(data));
    await api.patch(`/users/${userId}`, payload, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  }

  async getAccountsByIds(businessProfileId, bpseIds, searchEngineTypeId) {
    let response = await api.get(`businessProfiles/${businessProfileId}/accounts`, {
      params: {
        businessProfileSearchEngineIds: bpseIds,
        searchEngineTypeId
      }
    });
    return response.data;
  }

  async linkCatalogAndBpse(businessProfileId, catalogId, bpseId, searchEngineProductCatalogId) {
    await api.post(`/businessProfiles/${businessProfileId}/ProductCatalogs/${catalogId}/accounts/${bpseId}`, { searchEngineProductCatalogId });
  }

  async getBusinessProfileUsers(businessProfileId) {
    let response = await api.get(`/users?businessProfileIds=${businessProfileId}`);
    return response.data.data;
  }
}
