import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import { errorCodes } from './apiErrorCodes';
import { EntityAlreadyExistsError } from 'GlobalErrors/EntityAlreadyExistsError';
import { EntityNotFoundInCacheError } from 'GlobalErrors/EntityNotFoundInCacheError';
import { StripeError } from 'GlobalErrors/StripeError';
import { InvalidPasswordError } from 'GlobalErrors/InvalidPasswordError';
import { InvalidPortalRequestedException } from 'GlobalErrors/InvalidPortalRequestException';
import { InvalidHashkeyRequested } from '../errors/InvalidHashkeyRequested';
import { EmailAlreadyVerifiedError } from '../errors/EmailAlreadyVerifiedError';
import { EntityDoesNotExistException } from '../errors/EntityDoesNotExistException';
import { CampaignStatusUpdateError } from '../errors/CampaignStatusUpdateError';
import { InvalidAccessTokenError } from '../errors/InvalidAccessTokenError';
import { InvalidLoginCredentials } from 'GlobalErrors/InvalidLoginCredentials';
import { ForbiddenPortalRequested } from 'GlobalErrors/ForbiddenPortalRequested';
import { InvalidRequestDataException } from '../errors/InvalidRequestDataException';
import { ReviewManagementConfigurationError } from '../errors/ReviewManagementConfigurationError';
import Router from '../router';
import { LoginRoute } from 'Features/login/router.js';
import { ForgotPasswordRouteObjects } from '../features/forgotPassword/router';
import { UserAccountExpiredException } from 'GlobalErrors/UserAccountExpiredException';
import { i18n } from '@/lang/'
import { PageNotFoundRoute } from '../router/pageNotFound';
import { CampaignAlreadyExistsException } from '../errors/CampaignAlreadyExistsException';
import { ShortCodeAlreadyExistsException } from '../errors/ShortCodeAlreadyExistsException.js';
import { store } from 'Store/store';
import { AuthorizationCodeInvalidException } from '../errors/AuthorizationCodeInvalidException.js';
import { AuthorizationCodeExpiredException } from '../errors/AuthorizationCodeExpiredException.js';

var notifyError = function (error) {
  if (error && error.response && error.response.data && error.response.data.message) {
    Bugsnag.notify(new Error(error.response.data.message));
  } else {
    Bugsnag.notify(error);
  }
  Vue.notify({
    group: 'transientErrors',
    type: 'error',
    text: i18n.tc('message.somethingWentWrong')
  });
};

const unauthenticatedApiEndpoints = ['/v1.0/portals'];

export async function handleError(error) {
  if (
    error &&
        error.response &&
        (error.response.data ||
            error.response.status === errorCodes.Unauthorized)
  ) {
    var errorCode =
            error.response.data.errorCode === undefined
              ? error.response.status
              : error.response.data.errorCode;
    switch (errorCode) {
      case errorCodes.EntityAlreadyExistsError:
        return Promise.reject(new EntityAlreadyExistsError(error)); // do not notify error in case of error code 101
      case errorCodes.StripeException:
        return Promise.reject(
          new StripeError(error.response.data.message)
        );
      case errorCodes.InvalidRequestDataException:
        return Promise.reject(new InvalidRequestDataException(error));
      case errorCodes.InvalidHashkeyRequested:
        return Promise.reject(new InvalidHashkeyRequested(error));
      case errorCodes.EmailAlreadyVerifiedError:
        return Promise.reject(new EmailAlreadyVerifiedError(error));
      case errorCodes.EntityDoesNotExistException:
        return Promise.reject(new EntityDoesNotExistException(error));
      case errorCodes.UserAccountExpiredException:
        return Promise.reject(new UserAccountExpiredException(error));
      case errorCodes.InvalidPortalRequestedException:
        if (
          Router.currentRoute.name ===
                    ForgotPasswordRouteObjects.forgotPassword.name
        ) {
          return Promise.reject(
            new InvalidPortalRequestedException(error)
          );
        }
        else {
          invalidRoute(error);
        }
        return Promise.reject();
      case errorCodes.InvalidLoginCredentials:
        if (Router.currentRoute.name === LoginRoute.name) {
          return Promise.reject(new InvalidLoginCredentials(error));
        } else {
          invalidRoute(error);
        }
        return Promise.reject();
      case errorCodes.ForbiddenPortalRequested:
        if (Router.currentRoute.name === LoginRoute.name) {
          return Promise.reject(new ForbiddenPortalRequested(error));
        } else {
          invalidRoute(error);
        }
        return Promise.reject();
      case errorCodes.ForbiddenBusinessProfileRequested:
      case errorCodes.InvalidBusinessProfileRequested:
      case errorCodes.Unauthorized:
      case errorCodes.TokenMalformedException:
      case errorCodes.TokenInvalidException:
      case errorCodes.TokenExpiredException:
        invalidRoute(error);
        return Promise.reject(new InvalidAccessTokenError(error));
      case errorCodes.EntityNotFoundInCacheException:
        return Promise.reject(new EntityNotFoundInCacheError(error));
      case errorCodes.MediaPlanStatusUpdateFailedException:
      case errorCodes.NoCampaignsLinkedException:
        return Promise.reject(new CampaignStatusUpdateError(error));
      case errorCodes.ReviewManagementConfigurationError:
        return Promise.reject(new ReviewManagementConfigurationError(error));
      case errorCodes.CampaignAlreadyExistsException:
        return Promise.reject(new CampaignAlreadyExistsException(error));
      case errorCodes.ShortCodeAlreadyExistsException:
        return Promise.reject(new ShortCodeAlreadyExistsException(error));
      case errorCodes.AuthorizationCodeInvalidException:
        return Promise.reject(new AuthorizationCodeInvalidException(error));
      case errorCodes.AuthorizationCodeExpiredException:
        return Promise.reject(new AuthorizationCodeExpiredException(error));
      default:
        notifyError(error);
        return Promise.reject(error);
    }
  } else if (error) {
    notifyError(error);
    return Promise.reject(error);
  }
  else {
    notifyError('An unknown error has occurred');
    return Promise.reject();
  }
}

function invalidRoute(error) {
  let requestUrl = new URL(error && error.config && error.config.url);
  if(unauthenticatedApiEndpoints.includes(requestUrl.pathname)){
    // fallback to avoid indefinite redirect
    Router.push({
      name: PageNotFoundRoute.name,
      params: [Router.currentRoute.path], // https://github.com/vuejs/vue-router/issues/724#issuecomment-663315290
      replace: true
    });
    return;
  }

  store.dispatch('setUser', null);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  let redirectPath = '/profileSelection';
  // handling cases where currentRoute's fullPath is the rootlevel route '/' (which points to nothing)
  // In such cases check if any pending routes Or set redirect as profileSelection
  if (Router.currentRoute.fullPath && Router.currentRoute.fullPath != '/'){
    redirectPath = Router.currentRoute.fullPath;
  } else if (Router.history && Router.history.pending && Router.history.pending.fullPath && Router.history.pending.fullPath != '/') {
    redirectPath = Router.history.pending.fullPath;
  }
  if (Router.currentRoute.name != LoginRoute.name) {
    Router.push({
      name: LoginRoute.name,
      query: {
        redirect: redirectPath
      }
    });
  }
}
