import { gridLayoutPositions } from '../constants/gridLayout';
import { getLayoutPosition } from '../helpers/gridLayoutHelper';
import { Path } from 'fabric';
import { imageFits } from '../constants/image'
import { store as $store } from 'Store/store'
import { layers } from '../constants/layers';
import { getImageDimensions } from './multipleSourcesHelper';

export const relativeProximityFactor = 0.95;

export const hasScaleChanged = (layerOne, layerTwo) => {
  let changed = (layerOne.data.styles.scaleX !== layerTwo.data.styles.scaleX || layerOne.data.styles.scaleY !== layerTwo.data.styles.scaleY);
  if(!changed && layerOne.data.styles.defaultScaleX && layerTwo.data.styles.defaultScaleX){
    changed = (layerOne.data.styles.defaultScaleX !== layerTwo.data.styles.defaultScaleX || layerOne.data.styles.defaultScaleY !== layerTwo.data.styles.defaultScaleY);
  }
  return changed;
}

export const setScalingBounds = function(layer, localLayer, fitOptionsInfo) {
  if (!(layer && layer.data)) return;
  const { props, styles } = layer.data;

  const InDefaultVariant = $store.getters.InDefaultVariant;

  let { width, height } = getImageDimensions(layer.data);

  // to handle olderTemplates which dont have fitoptions as a property
  if(fitOptionsInfo && fitOptionsInfo.inImageLayer && layer.data.oldTemplateWithNoFitOptionsProperty){
    layer.data.oldTemplateWithNoFitOptionsProperty = false;
  }

  // check if localLayer is passed, if yes then use boundWidth and boundHeight from localLayer else from global layer
  let boundWidth = (localLayer && localLayer.data && localLayer.data.props && localLayer.data.props.boundWidth) ? localLayer.data.props.boundWidth : width * styles.scaleX;
  let boundHeight = (localLayer && localLayer.data && localLayer.data.props && localLayer.data.props.boundHeight) ? localLayer.data.props.boundHeight : height * styles.scaleY;

  // when it is image
  if(props.imageUrl){
    if(!InDefaultVariant){
      // when image is manual scaling from layer panel
      if(fitOptionsInfo?.panelScaling){
        Object.assign(layer.data.props, {
          boundWidth: props.defaultWidth * styles.defaultScaleX,
          boundHeight: props.defaultHeight * styles.defaultScaleY
        });
      }
      else{
        // when user adds new image directly on variants other than default then we need to assign defaultWidth && defaultHeight
        if(!props.defaultWidth && !props.defaultHeight){
          Object.assign(layer.data.props, {
            boundWidth,
            boundHeight,
            defaultWidth: width,
            defaultHeight: height
          });
        }
        else{
          Object.assign(layer.data.props, {
            boundWidth,
            boundHeight
          });
        }
      }
    }
    else{
      // in default Variant
      Object.assign(layer.data.props, {
        boundWidth: width * (fitOptionsInfo && fitOptionsInfo.draging ? styles.scaleX : styles.defaultScaleX),
        boundHeight: height * (fitOptionsInfo && fitOptionsInfo.draging ? styles.scaleY : styles.defaultScaleY),
        defaultWidth: width,
        defaultHeight: height
      });
    }
  }
  else{
    Object.assign(layer.data.props, {
      boundWidth,
      boundHeight
    });
  }

  if (props.renderSrc && fitOptionsInfo && fitOptionsInfo.imageObjectWidth && fitOptionsInfo.imageObjectHeight) {
    boundWidth = fitOptionsInfo.imageObjectWidth * styles.scaleX;
    boundHeight = fitOptionsInfo.imageObjectHeight * styles.scaleY;
    Object.assign(layer.data.props, {
      boundWidth,
      boundHeight
    });
  }
  /*
      In case of local layer only the diff will be stored, we use computedLocalLayer within method/block scope
      to get other missing properties from the global layer,above block will update the computedLocalLayer and not the diff.
      Below code will take care of localLayer if passed with any.
  */
  if (localLayer) {
    localLayer.data = localLayer.data || {};
    localLayer.data.props = localLayer.data.props || {};
    Object.assign(localLayer.data.props, {
      boundWidth,
      boundHeight
    });
  }
}

export const scaleToBoundSize = function(layerToUpdate, data, localLayer) {
  // Rescale to bound width and height.
  // Only applies to images from templates when a different image is selected.
  if (!layerToUpdate.data || !layerToUpdate.data.props.hasOwnProperty('boundWidth') || !layerToUpdate.data.props.hasOwnProperty('boundHeight'))
    return;
  const { boundWidth, boundHeight } = layerToUpdate.data.props;
  let { width, height } = getImageDimensions(data);
  // Scale upto 4 decimal places, eg. 0.5523 (55.23% on UI)
  let scaleX = Math.round((boundWidth / width) * 10000) / 10000;
  let scaleY = Math.round((boundHeight / height) * 10000) / 10000;

  const InDefaultVariant = $store.getters.InDefaultVariant;

  if(!InDefaultVariant && layerToUpdate.type === layers.image){
    calculateScaleForFitOption(data, width, height);
  }
  else{
    data.styles.scaleX = scaleX;
    data.styles.scaleY = scaleY;
    data.styles.defaultScaleX = scaleX;
    data.styles.defaultScaleY = scaleY;
  }
  // to handle olderTemplates which dont have fitoptions as a property
  if(InDefaultVariant && data.oldTemplateWithNoFitOptionsProperty){
    if(data.styles.selectedFit === imageFits.fillToBounds){
      data.styles.scaleX = data.styles.scaleY = data.styles.scaleX < data.styles.scaleY ? data.styles.scaleX : data.styles.scaleY
    }
    else if(data.styles.LocalChangesWithAspectRatio){
      if(scaleX < scaleY){
        data.props.boundWidth = scaleX * width;
        data.props.boundHeight = scaleX * height;
        data.styles.scaleX = data.styles.scaleY = scaleX
      }
      else{
        data.props.boundWidth = scaleY * width;
        data.props.boundHeight = scaleY * height;
        data.styles.scaleX = data.styles.scaleY = scaleY
      }
      data.styles.LocalChangesWithAspectRatio = false;
    }
    if(!data.styles.LocalChangesWithAspectRatio && localLayer && data.styles.selectedFit === imageFits.fillToBounds){
      data.styles.scaleX = boundWidth / width;
      data.styles.scaleY = boundHeight / height;
    }
  }
  /*
      In case of local layer only the diff will be stored, we use computedLocalLayer within method/block scope
      to get other properties missing properties from the global layer,above block will update the computedLocalLayer and not the diff.
      Below code will take care of localLayer if passed with any.
  */
  let styles = {
    scaleX: data.styles.scaleX,
    scaleY: data.styles.scaleY
  }
  if (localLayer) {
    localLayer.data = localLayer.data || {};
    localLayer.data.styles = localLayer.data.styles || {};
    Object.assign(localLayer.data.styles, styles);
  }
}
export const calculateRelativeScales = (boundWidth, boundHeight, objectWidth, objectHeight, scaleX, scaleY, relativeToAd) => {
  let relativeScales = {
    relativeScaleX: boundWidth / (objectWidth * scaleX),
    relativeScaleY: boundHeight / (objectHeight * scaleY)
  };
  if(relativeToAd) {
    // If the scaling is relative to ad dimesions
    let relativeScale = Math.min(((relativeProximityFactor * boundWidth) / objectWidth), ((relativeProximityFactor * boundHeight) / objectHeight));
    relativeScales.relativeScaleX = relativeScales.relativeScaleY = relativeScale;
  }
  else {
    // If the scaling is relative to another object
    const differenceInWidth = ((boundWidth - objectWidth) / boundWidth) * 100,
      differenceInHeight = ((boundHeight - objectHeight) / boundHeight) * 100;
    // If difference in dimensions is large
    if(Math.abs(differenceInHeight) > 2 || Math.abs(differenceInWidth) > 2) {
      if(differenceInWidth < 0 || differenceInHeight < 0) {
      // If the new object is larger, resize it to the bound dimensions
        let relativeScale;
        if(boundWidth == boundHeight) {
          relativeScale = objectWidth > objectHeight ? boundWidth / (objectWidth * scaleX) : boundHeight / (objectHeight * scaleY);
          relativeScales = {
            relativeScaleX: relativeScale,
            relativeScaleY: relativeScale
          }
        }
        else {
          relativeScale = boundWidth < boundHeight ? boundWidth / (objectWidth * scaleX) : boundHeight / (objectHeight * scaleY);
        }
        relativeScales = {
          relativeScaleX: relativeScale,
          relativeScaleY: relativeScale
        }
      }
      else {
      // If the new object is smaller, then keep its dimensions
        const relativeScale = objectWidth < objectHeight ? 1 / scaleX : 1 / scaleY;
        relativeScales = {
          relativeScaleX: relativeScale,
          relativeScaleY: relativeScale
        }
      }
    }
  }
  return relativeScales;
}
export const relativelyScale = (adLayer, ad, props, styles, currentSrc, newSrc, isTemplateLoad) => {
  let localLayer = ad.localLayers.find(layer => layer.id == adLayer.parentId),
    scaleX = styles.scaleX, scaleY = styles.scaleY;
  const fabricObject = adLayer.fabricObject;
  const relativeProps = adLayer.relativeProps ? adLayer.relativeProps : {};
  if(!adLayer.relativeProps) {
    adLayer.relativeProps = relativeProps;
  }
  if(!ad.isInFocusMode || currentSrc === ''){
    let relativeScales = {
      relativeScaleX: 1,
      relativeScaleY: 1
    };
    if(isTemplateLoad && relativeProps && relativeProps.relativeScaleX && relativeProps.relativeScaleY) {
      // If this is a template load, the relative scales are already present so don't recompute
      relativeScales = {
        relativeScaleX: relativeProps.relativeScaleX,
        relativeScaleY: relativeProps.relativeScaleY
      }
    }
    else if(currentSrc === '') {
      // If selected for the first time, then find the relative scale based on the ad dimensions
      if((ad.width <= width || ad.height <= height)) {
        relativeScales = calculateRelativeScales(ad.width, ad.height, width, height, scaleX, scaleY, true);
      }
      relativeProps.relativeScaleX = relativeScales.relativeScaleX;
      relativeProps.relativeScaleY = relativeScales.relativeScaleY;
    }
    // Case 2: Content has changed, re-calculate the relative scale if needed.
    else if (currentSrc != newSrc) {
      relativeScales = calculateRelativeScales(
        fabricObject.getScaledWidth(),
        fabricObject.getScaledHeight(),
        width,
        height,
        scaleX,
        scaleY,
        false
      );
      relativeProps.relativeScaleX = relativeScales.relativeScaleX;
      relativeProps.relativeScaleY = relativeScales.relativeScaleY;
    }
    if (!(localLayer && localLayer.isDirty) || (currentSrc != newSrc)) {
      scaleX = styles.scaleX * relativeProps.relativeScaleX;
      scaleY = styles.scaleY * relativeProps.relativeScaleY;
    }
  }
  return {
    scaleX,
    scaleY
  }
}
export const getPositionDiference = (oldScales, objectDimensions, adLayoutPosition,  ad, newScales, position, hasOriginChanged) => {
  let styles = {
    scaleX: parseFloat(newScales.scaleX.toFixed(4)),
    scaleY: parseFloat(newScales.scaleY.toFixed(4))
  }
  var originalLayoutPosition = getLayoutPosition(
    { width: ad.width, height: ad.height },
    { width: objectDimensions.width * styles.scaleX, height: objectDimensions.height * styles.scaleY },
    adLayoutPosition.positionIndex,
    adLayoutPosition.positionOffset,
    !hasOriginChanged
  );
  const hasImagePositionChanged = originalLayoutPosition.top != position.top || originalLayoutPosition.left != position.left;
  const hasScaleChanged = oldScales.scaleX != styles.scaleX || oldScales.scaleX != styles.scaleY;
  let differenceInPosition = {
    top: 0,
    left: 0
  };
  if(hasImagePositionChanged || hasScaleChanged) {
    differenceInPosition = {
      top: position.top - originalLayoutPosition.top,
      left: position.left - originalLayoutPosition.left
    }
  }
  return differenceInPosition;
}
export const getRelativeScalesForClip = (clipPath, { scaleX, scaleY }, fabricObject, canvas) => {
  const objectHeight = fabricObject.getScaledHeight(), objectWidth = fabricObject.getScaledWidth(),
    objectTop = fabricObject.getBoundingRect().top, objectLeft = fabricObject.getBoundingRect().left;
  // set the new scale to 80% of the object
  let relativeScaleX = (objectWidth * 0.8) / (clipPath.width * scaleX),
    relativeScaleY = (objectHeight * 0.8) / (clipPath.height * scaleY);
  const isImageOutsideCanvas = objectTop + objectHeight > canvas.height ||
      objectTop < 0 ||
      objectLeft + objectWidth > canvas.width ||
      objectLeft < 0;
  if(isImageOutsideCanvas) {
    // If the object is outside the canvas, then the relative size of the clip must be reduced
    const objectWidthInsideCanvas = objectWidth - Math.abs(objectLeft);
    const objectHeightInsideCanvas = objectHeight - Math.abs(objectTop);
    relativeScaleY = relativeScaleY - ((objectHeight - objectHeightInsideCanvas) / objectHeight);
    relativeScaleX = relativeScaleX - ((objectWidth - objectWidthInsideCanvas) / objectWidth);
  }
  return {
    scaleX: relativeScaleX,
    scaleY: relativeScaleY
  };
}
export const getPositionForClip = (clipPath, fabricObject, canvas, hasOriginChanged) => {
  const objectHeight = fabricObject.getScaledHeight(), objectWidth = fabricObject.getScaledWidth(),
    objectTop = fabricObject.getBoundingRect().top, objectLeft = fabricObject.getBoundingRect().left;
  // set the new clip position to middle center
  let relativePosition = gridLayoutPositions.middleCentre;
  const position = getLayoutPosition(
    { width: objectWidth, height: objectHeight },
    { width: clipPath.getScaledWidth(), height: clipPath.getScaledHeight() },
    relativePosition,
    { hOffset: objectLeft, vOffset: objectTop },
    !hasOriginChanged
  );
  if(objectLeft < 0 || objectTop < 0) {
    // If the object is starting outside the top-left of the canvas,
    // then try to position the clip to bottom-right of object
    position.top = objectTop + objectHeight - (clipPath.getScaledHeight() / 2);
    position.left = objectLeft + objectWidth - (clipPath.getScaledWidth() / 2);
  }
  else if(objectTop + objectHeight > canvas.height || objectLeft + objectWidth > canvas.width) {
    // If the object ending outside the bottom-right of the canvas,
    // then try to position the clip to top-left of object
    position.top = fabricObject.top;
    position.left = fabricObject.left;
  }
  return position;
}
export const createClip = (filters, styles, canvas, adLayer, isTemplateLoad) => {
  /**
  * The way clip works in fabric is that once you add a clipPath to an object, the clip object is no longer accessible
  * for interactive edit. Setting it as the active object will only result in the selecting the object as a whole.
  * To overcome this, we create a separate object on the canvas for the clip and then assign it to object's clipPath.
  * This way, we can maintain the interactivity of the clip object.
  */
  const { clipping } = filters;
  const relativeProps = adLayer.relativeProps;
  if(!relativeProps.clipping) {
    relativeProps.clipping = {
      scaleX: 1,
      scaleY: 1,
      top: 0,
      left: 0,
      /**
       * This flag determines if relative properties are computed or not for the particular ad.
       * If this is an old template, then we set this to true to avoid computation.
      */
      defaultsComputed: isTemplateLoad ? true : false,
      /**
       * This flag determines if position needs to be recomputed. This is needed when focus mode is reset
       * and the position needs to be computed based on the global layer.
      */
      recomputePosition: false
    };
  }
  // Create a new clip object
  const clipPath = new Path(clipping.clipPath, {
    originX: clipping.originX,
    originY: clipping.originY,
    scaleX: clipping.scaleX * relativeProps.clipping.scaleX,
    scaleY: clipping.scaleY * relativeProps.clipping.scaleY,
    angle: clipping.rotateAngle + styles.angle,
    absolutePositioned: true,
    visible: true,
    selectable: false,
    evented: false,
    opacity: 1,
    fill: '#FFFFFF00',
    /**
     * Disable the scale cache so that the clip is updated along with the clipPath scaling.
     * If this is set to true, then the clip is not updated until after mouse drag for scaling is completed.
    */
    noScaleCache: false,
    // Disable align gridlines w.r.t other objects.
    skipAlign: true
  });
  const fabricObject = adLayer.fabricObject;
  let isClipActive = false;
  const hasClipShapeChanged = !adLayer.clipPath || JSON.stringify(adLayer.clipPath.path) != JSON.stringify(clipPath.path);
  if(adLayer.clipPath) {
    if(canvas.getActiveObject() == adLayer.clipPath) {
      isClipActive = true;
    }
    // Remove the old clip object from canvas
    removeClip(canvas, adLayer, isClipActive);
  }
  let position = {
    top: relativeProps.clipping.top,
    left: relativeProps.clipping.left
  }
  if(!isTemplateLoad && (!relativeProps.clipping.defaultsComputed || hasClipShapeChanged)) {
    // If this is not a loaded template AND
    // If this is first time clip is being added, OR if the clip shape has changed
    // Compute some 'sensible' defaults
    const relativeScales = getRelativeScalesForClip(
      clipPath,
      { scaleX: clipping.scaleX, scaleY: clipping.scaleY },
      fabricObject,
      canvas
    );
    clipPath.set({
      scaleX: clipping.scaleX * parseFloat(relativeScales.scaleX.toFixed(4)),
      scaleY: clipping.scaleY * parseFloat(relativeScales.scaleY.toFixed(4))
    });
    position = getPositionForClip(clipPath, fabricObject, canvas, styles.hasOriginChanged);
    Object.assign(relativeProps.clipping, { ...relativeScales, ...position, defaultsComputed: true });
  }
  else if(relativeProps.clipping.recomputePosition) {
    position = getPositionForClip(clipPath, fabricObject, canvas, styles.hasOriginChanged);
    Object.assign(relativeProps.clipping, { ...position, recomputePosition: false });
  }
  position.top +=  clipping.clipPositionOffsets.vOffset;
  position.left +=  clipping.clipPositionOffsets.hOffset;
  clipPath.set({ ...position });
  adLayer.clipPath = clipPath;
  canvas.add(clipPath);
  clipPath.setCoords();
  isClipActive && canvas.setActiveObject(clipPath);
  return clipPath;
}
export const removeClip = (canvas, adLayer, setObjectActive = true) => {
  if(adLayer.clipPath) {
    setObjectActive && canvas.setActiveObject(adLayer.fabricObject);
    canvas.remove(adLayer.clipPath);
    canvas.renderAll();
    adLayer.clipPath = null;
  }
}
export const boundClipPositionToObject = (clipPath, fabricObject) => {
  const objectHeight = fabricObject.getScaledHeight(),
    objectWidth = fabricObject.getScaledWidth()
  clipPath.setCoords();
  // top-left corner
  if(
    clipPath.getBoundingRect().top < fabricObject.getBoundingRect().top ||
      clipPath.getBoundingRect().left < fabricObject.getBoundingRect().left
  ){
    clipPath.top = Math.max(
      clipPath.top,
      clipPath.top - clipPath.getBoundingRect().top + fabricObject.getBoundingRect().top
    );
    clipPath.left = Math.max(
      clipPath.left,
      clipPath.left - clipPath.getBoundingRect().left + fabricObject.getBoundingRect().left
    );
  }
  // bot-right corner
  if(
    clipPath.getBoundingRect().top + clipPath.getBoundingRect().height >
        fabricObject.getBoundingRect().top + objectHeight ||
      clipPath.getBoundingRect().left + clipPath.getBoundingRect().width >
        fabricObject.getBoundingRect().left + objectWidth
  ){
    clipPath.top = Math.min(
      clipPath.top,
      objectHeight - clipPath.getBoundingRect().height + clipPath.top - clipPath.getBoundingRect().top + fabricObject.getBoundingRect().top
    );
    clipPath.left = Math.min(clipPath.left,
      objectWidth - clipPath.getBoundingRect().width + clipPath.left - clipPath.getBoundingRect().left + fabricObject.getBoundingRect().left
    );
  }
};
export const boundClipSizeToObject = (clipPath, fabricObject, previousState) => {
  const objectHeight = fabricObject.getScaledHeight(),
    objectWidth = fabricObject.getScaledWidth()
  clipPath.setCoords();
  //left border
  if(clipPath.getBoundingRect().left < fabricObject.getBoundingRect().left) {
    clipPath.left = previousState.left;
    clipPath.scaleX = previousState.scaleX
  } else {
    previousState.left = clipPath.left;
    previousState.scaleX = clipPath.scaleX;
  }
  //right border
  if((previousState.scaleX * clipPath.width) + clipPath.getBoundingRect().left >= fabricObject.getBoundingRect().left + objectWidth) {
    clipPath.scaleX = (fabricObject.getBoundingRect().left + objectWidth - clipPath.getBoundingRect().left) / clipPath.width;
    clipPath.setCoords();
    clipPath.left = objectWidth - (clipPath.scaleX * clipPath.width) + clipPath.left - clipPath.getBoundingRect().left + fabricObject.getBoundingRect().left
  } else {
    previousState.scaleX = clipPath.scaleX;
  }
  //top border
  if(clipPath.getBoundingRect().top < fabricObject.getBoundingRect().top) {
    clipPath.top = previousState.top;
    clipPath.scaleY = previousState.scaleY;
  } else {
    previousState.top = clipPath.top;
    previousState.scaleY = clipPath.scaleY;
  }
  //bottom border
  if((previousState.scaleY * clipPath.height) + clipPath.getBoundingRect().top >= fabricObject.getBoundingRect().top + objectHeight) {
    clipPath.scaleY = (fabricObject.getBoundingRect().top + objectHeight - clipPath.getBoundingRect().top) / clipPath.height;
    clipPath.setCoords();
    clipPath.top = objectHeight - (clipPath.scaleY * clipPath.height) + clipPath.top - clipPath.getBoundingRect().top + fabricObject.getBoundingRect().top
  } else {
    previousState.scaleY = clipPath.scaleY;
  }
};

//Calculates scaleX and scaleY based on fit options. used to support fit options in multiple sources
export const calculateScaleForFitOption = (data, width, height) => {
  const fit = data.styles.selectedFit
  const { boundWidth, boundHeight } = data.props;
  let scaleX = Math.round((boundWidth / width) * 10000) / 10000;
  let scaleY = Math.round((boundHeight / height) * 10000) / 10000;

  switch (fit) {
    case imageFits.original:
    case imageFits.clipToBounds:
      data.styles.scaleX = 1;
      data.styles.scaleY = 1;
      break;
    case imageFits.stretchToFit:
      data.styles.scaleX = scaleX;
      data.styles.scaleY = scaleY;
      break;
    case imageFits.fitAndCrop:
      data.styles.scaleX = data.styles.scaleY = scaleX > scaleY ? scaleX : scaleY;
      break;
    case imageFits.fillToBounds:
      data.styles.scaleX = data.styles.scaleY = scaleX < scaleY ? scaleX : scaleY;
      break;
    case imageFits.crop:
      data.styles.scaleX = data.styles.scaleY = scaleX > scaleY ? scaleX : scaleY;
      break;
    default:
      break;
  }
}

// function to update image crop and position while swaping between image fit options
export const updateImageCropAndPosition = (fabricObject, data, adLayoutPosition, styles, ad, source = null) => {
  let { width, height } = source ? source : getImageDimensions(data);
  if($store.getters.InDefaultVariant){
    // switching between fitoptions in default variant, need to set crop values to zero
    fabricObject.cropY = 0;
    fabricObject.cropX = 0;
    fabricObject.height = height;
    fabricObject.width = width;
    return;
  }
  let scaleX = data.props.boundWidth / width;
  let scaleY = data.props.boundHeight / height;

  //previous crop values has to reset to zero before apply any other change like cover, clip
  fabricObject.cropY = 0;
  fabricObject.cropX = 0;
  fabricObject.height = height;
  fabricObject.width = width;

  let newLayoutPosition;
  const fit = data.styles.selectedFit;
  switch (fit) {
    case imageFits.fitAndCrop:
      if (scaleX > scaleY) {
        fabricObject.cropY = (((height * styles.scaleX) - data.props.boundHeight) / 2) / styles.scaleY;
        fabricObject.height = data.props.boundHeight / styles.scaleX;
        fabricObject.width = data.props.boundWidth / styles.scaleX;
      } else {
        fabricObject.cropX = (((width * styles.scaleX) - data.props.boundWidth) / 2) / styles.scaleY;
        fabricObject.width = data.props.boundWidth / styles.scaleY;
        fabricObject.height = data.props.boundHeight / styles.scaleY;
      }
      // After cropping the image, we need to apply a new left and top position
      newLayoutPosition = getLayoutPosition({ width: ad.width, height: ad.height }, { width: data.props.boundWidth, height: data.props.boundHeight }, adLayoutPosition.positionIndex, adLayoutPosition.positionOffset, !styles.hasOriginChanged);
      break;
    case imageFits.crop:
      if (scaleX > scaleY) {
        fabricObject.height = data.props.boundHeight / styles.scaleX;
        fabricObject.width = data.props.boundWidth / styles.scaleX;
      } else {
        fabricObject.width = data.props.boundWidth / styles.scaleY;
        fabricObject.height = data.props.boundHeight / styles.scaleY;
      }
      // After cropping the image, we need to apply a new left and top position
      newLayoutPosition = getLayoutPosition({ width: ad.width, height: ad.height }, { width: data.props.boundWidth, height: data.props.boundHeight }, adLayoutPosition.positionIndex, adLayoutPosition.positionOffset, !styles.hasOriginChanged);
      break;
    case imageFits.clipToBounds:
      let changed = false;
      if(data.props.boundWidth < width){
        changed = true;
        fabricObject.width = data.props.boundWidth;
      }
      if(data.props.boundHeight < height){
        changed = true;
        fabricObject.height = data.props.boundHeight;
      }
      if(changed){
        newLayoutPosition = getLayoutPosition({ width: ad.width, height: ad.height }, { width: data.props.boundWidth, height: data.props.boundHeight }, adLayoutPosition.positionIndex, adLayoutPosition.positionOffset, !styles.hasOriginChanged);
      }
      break;
    default:
      break;
  }
  // whenever we crop the image we need to calcualte new layout position and apply to fabricObject
  if (newLayoutPosition) {
    fabricObject.left = newLayoutPosition.left;
    fabricObject.top = newLayoutPosition.top;
  }
};