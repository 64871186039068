// Set node flag
global.isNode = () => false;

export const initialize = (axiosInstance, accessToken, baseURL) => {
  axiosInstance.defaults.baseURL = baseURL;
  axiosInstance.interceptors.request.use((config) => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
}