import { v4 as uuidv4 } from 'uuid';
import { GoogleTextToSpeechService } from '../services/googleTextToSpeechService';
import { apiPayload, createAudioFabricObject, updateAudioElement, replaceWithCDN } from '../helpers/runtimeHelper';
import { Textbox } from 'fabric';
import { deepAssign, getLoaderTextObjectData } from './commonHelper';
import { getInsertAtIndex } from './commonHelper';
import { defaultTtsConfiguration } from '../../externalTools/constants/audio';

export const getAudioLayerData = () => {
  return {
    props: {
      id: '',
      businessProfileId: '',
      src: '',
      duration: 0,
      startTime: 0,
      endTime: 0,
      useTts: false,
      ttsConfiguration: JSON.parse(JSON.stringify(defaultTtsConfiguration))
    },
    styles: {
      selectable: false
    },
    hidden: false,
    adLayoutPositions: [],
    isEditing: false,
    description: ''
  }
}
/*
  If you want to add more properties to the layer, add them above and if they supported for focus mode
  then add them in supportedFocusModeProperties array in externalTools/constants/layers.js
*/

export const addAudioToCanvas = async function (layers, ad, layer, existingLayer) {
  const loaderTextData = getLoaderTextObjectData(layer.data.hidden);
  let loaderTextFabricObject = new Textbox('Loading audio...', loaderTextData);
  ad.canvas && ad.canvas.add(loaderTextFabricObject) && ad.canvas.centerObject(loaderTextFabricObject) && ad.canvas.renderAll();
  const audioFabricObject = await createAudioFabricObject(ad, layer);

  if (existingLayer) {
    audioFabricObject.layerId = existingLayer.parentId;
    existingLayer.fabricObject = audioFabricObject;
  }
  else {
    let newAdLayerId = uuidv4();
    audioFabricObject.layerId = layer.id;
    const layerIndex = layers.findIndex(l => l.id === layer.id);
    ad.layers.splice(layerIndex, 0, {
      id: newAdLayerId,
      parentId: layer.id,
      type: layer.type,
      fabricObject: audioFabricObject
    });
  }
  ad.canvas && ad.canvas.remove(loaderTextFabricObject);
  if(ad.canvas) {
    let canvasLayers = ad.canvas.getObjects();
    if(canvasLayers.length === 1) {
      ad.canvas.insertAt(0, audioFabricObject);
    }
    else {
      let insetAtIndex = getInsertAtIndex(layers, canvasLayers, layer.id, layer.id);
      ad.canvas.insertAt(insetAtIndex, audioFabricObject);
    }
    ad.canvas.renderAll();
  }
}

export const updateAudioLayer = (layerToUpdate, data) => {
  deepAssign(layerToUpdate.data, data);
  //Clone array because layerToUpdate.data.adLayoutPositions and data.adLayoutPositions have the same reference
  if(data.adLayoutPositions){
    let clone = data.adLayoutPositions.slice(0);
    // avoiding changing array reference and simply emptying it and pushing the new items
    // This is done so that reactivity is not lost
    layerToUpdate.data.adLayoutPositions.length = 0;
    layerToUpdate.data.adLayoutPositions.push(...clone);
  }
}

export const updateAudioObject = async (payload) => {
  const { ad, layerToUpdate, data, layerData, state, ignoreLoader } = payload;
  let fabricObject = layerToUpdate.fabricObject;
  let loaderTextFabricObject;
  if (!ignoreLoader) {
    const loaderTextData = getLoaderTextObjectData(data.hidden);
    loaderTextFabricObject = new Textbox('Loading audio...', loaderTextData);
    ad.canvas && ad.canvas.add(loaderTextFabricObject) && ad.canvas.centerObject(loaderTextFabricObject) && ad.canvas.renderAll();
  }
  // if props updated, change audio element
  if(data.props){
    await updateAudioElement(fabricObject, layerData.data, state, layerToUpdate);
  }
  if (!ignoreLoader && ad.canvas) {
    ad.canvas && ad.canvas.remove(loaderTextFabricObject);
    ad.canvas.remove(loaderTextFabricObject);
  }
  fabricObject.visible = !data.hidden;
  fabricObject.layerId = layerToUpdate.parentId;
  ad.canvas.renderAll();
}

export const getSpeechData = async (props) => {
  const { getSpeechForText } = new GoogleTextToSpeechService(apiPayload);
  let data;
  if (props.ttsConfiguration.isApiCallRequired) {
    props.ttsConfiguration.isApiCallRequired = false;
    if (!props.ttsConfiguration.text) {
      props.ttsConfiguration.data = data = '';
    } else {
      let response = await getSpeechForText(props.ttsConfiguration, apiPayload.businessProfileId);
      props.ttsConfiguration.data = data = `data:audio/mp3;base64, ${response}`;
    }
  }
  else {
    data = props.ttsConfiguration.data;
  }
  return data;
}

export const loadAudioElement = async (audioElement) => {
  return new Promise((resolve, reject) => {
    audioElement.load();
    audioElement.onloadeddata = () => {
      resolve(audioElement);
    }
    audioElement.onerror = () => {
      reject();
    }
  });
}

export const createAudioElement = async (audioSrc) => {
  let audioElement = new Audio();
  audioElement.id = 'audio' + uuidv4();
  audioElement.src = replaceWithCDN(audioSrc);
  audioElement.style.display = 'none';
  audioElement.crossOrigin = 'anonymous';
  audioElement.preload = 'metadata';
  await loadAudioElement(audioElement);
  return audioElement;
}

export const getAudioMetaData = async (audioSrc) => {
  let audioElement = await createAudioElement(audioSrc);
  return {
    duration: audioElement.duration
  }
}